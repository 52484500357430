import { Form, DatePicker } from 'antd';
import { Label } from '../customComponents';

const TimeRangeInput = ({ displayText, label, required=true, fieldSize, validators = [], ...rest }) => {

    return (
        <Form.Item
            label={<Label>{displayText}</Label>}
            name={label}
            rules={[
                {
                    required,
                    message: `Please input a ${displayText}`,
                },
                ...validators
            ]}
        >
            
            <DatePicker.RangePicker
                showTime={{ format: 'HH:mm' }}
                disabledDate={(date) => date < Date.now()}
                format="YYYY-MM-DD HH:mm"
                {...rest} 
            />

        </Form.Item>
    )
};

export default TimeRangeInput