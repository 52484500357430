import InfoCard from '../elements/InfoCard'

export default ({ tracking, title, time, customer, email, price, status, onClick, profile }) => {
  const STATUSES = {
    'PENDING': tracking ? 'Seller Review' : 'Add Estimates',
    'REVIEWED': tracking ? 'Review Invoice' : 'Pending Approval',
    'DECLINED': 'Cancelled',
    'ACCEPTED': 'In Progress',
    'COMPLETE': 'Delivered',
    'PAYMENT': tracking ? 'Receipt Confirmation' : 'Check Payment'
  }

  return (
    <InfoCard 
      onClick={onClick}
      desc={email}
      price={price ? price : null}
      theme={profile.theme}
      time={time}
      title={customer}
      status={STATUSES[status]}
      subtitle={title}
    />
  )
}