import { Form, Input, Upload, Select} from 'antd';
import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import TextInput from '../inputs/TextInput';
import BigTextInput from '../inputs/BigTextInput';
import ToggleInput from '../inputs/ToggleInput';
import { useState, useEffect, useRef } from 'react';
import DateRangeInput from '../inputs/DateRangeInput'
import { pricePositive, charLimitValidator } from '../utils/validators'
import Submit from "../inputs/Submit";
import LocationInput from '../inputs/LocationInput'
import { RegularButton, Label } from '../customComponents'
import moment from 'moment' 

import {
  useNavigate,
  useParams,
} from "react-router-dom";
import Info from '../elements/Info';

export default ({ cloudUtils, setLoading, eventBookId }) => {
  let { profileId } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [name, setName] = useState()
  const [eventBook, setEventBook] = useState()
  const [profile, setProfile] = useState({})
  const [cost, setCost] = useState()
  const [currency, setCurrency] = useState('CAD')
  const [free, setFree] = useState(!profile.stripe)

  const [place, setPlace] = useState()
  const [city, setAddress] = useState()


  const inputRef = useRef(null);


  useEffect(() => {
    cloudUtils.getDocument("event", eventBookId, setEventBook)
    cloudUtils.getDocument("profile", profileId, setProfile)
  }, [])

  useEffect(() => {
    if (eventBook){  
      let newEventBook = {...eventBook}
      setName(newEventBook.name)
      setCost(newEventBook.cost.value)
      setCurrency(newEventBook.cost.currency)
      setFree(newEventBook.cost.free)

      inputRef.current.setValue(newEventBook.city)

      newEventBook.date = newEventBook.date.map((time) => moment(time))
      newEventBook.cost = newEventBook.cost.value ? newEventBook.cost.value : 0
      form.setFieldsValue(newEventBook);
    }
  }, [eventBook])


  let addEventBook = async (raw) => {
    // setLoading(true)
    let { file, cost, date, ...values } = raw

    values.cost = {
      free,
      currency,
      value: parseFloat(cost)
    }
    
    values.date = date.map((t) => t.valueOf())
    values.city = city
    values.attendees = [{user: profileId, status: 'HOSTING'}]
    values.primary = profileId
    let eventBook = eventBookId || uuidv4()

    if (file){
      values.serviceImage = eventBook + '/' + new Date().getTime() + '.' + file[0].name.split('.').pop()
      await cloudUtils.uploadFile(values.serviceImage, file)
    }
 
    let filtered = Object.fromEntries(
      Object.entries(values)
      .filter(([k,v]) => v)
    )

    await cloudUtils.updateDocument("event", eventBook, filtered, 'MERGE')
    if (profile.events && !profile.events.map(({id}) => id).includes(eventBook)){
      await cloudUtils.updateDocument("profile", profileId, {events: [{id: eventBook, starred: false}, ...profile.events]}, 'UPDATE')
    } else {
      await cloudUtils.updateDocument("profile", profileId, {events: [{id: eventBook, starred: false}]}, 'UPDATE')
    }
    navigate('/' + profileId)
  }


  const selectCurrency = (
    <Select defaultValue='CAD' value={currency} bordered={false} onSelect={setCurrency}>
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );



  return (
    <Form onFinish={addEventBook} form={form}  layout='vertical' requiredMark={false} >
      <TextInput
        required
        displayText="Event Name"
        label="name"
        validators={[charLimitValidator("Event Name", name, 18)]}
        onChange={(e) => {
          setName(e.target.value)
        }}
      />

      <BigTextInput required displayText="Description" label="description"/>

      {profile.stripe 
        ? <ToggleInput displayText="Free event?" label="free" state={free} setState={setFree} />
        : <Info displayText={["You can only host free events until you register your bank account information"]}/>
      }

      {!free ? (
        <TextInput 
          validators={[pricePositive(false, 0, 0, cost)]} 
          label="cost" 
          displayText="Cost"
          suffix={selectCurrency}
          onChange={(e) => {
            setCost(e.target.value)
          }}
        />
      ) : null}
      
      <DateRangeInput displayText={`Event Date`} label="date" />
      
      <LocationInput inputRef={inputRef} setPlace={setPlace} setAddress={setAddress}/>
      
      <Form.Item label={<Label>Cover Image</Label>} name='file' getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}>
        <Upload
          customRequest={({onSuccess}) => onSuccess('ok')}
          className="upload-list-inline" style={{'margin-bottom': '-24px'}}
          accept="image/*,video/*,audio/*"
        >
            <RegularButton shape="round"  icon={<UploadOutlined />}>Upload</RegularButton>
        </Upload>
      </Form.Item>
      
      <Submit text={ eventBookId ? 'Update' : 'Create' } icon={<PlusCircleOutlined />} />

    </Form>
  )

};
