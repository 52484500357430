const Dot = ({selected}) => <span className='indicator-dot' style={{opacity: selected ? '1' : '0.3'}} />

const IndicatorDots = ({total, index}) => {
  if (total < 2) {
    return <div className='indicator-wrapper' />
  } else {
    return (
      <div className='indicator-wrapper' >{
        Array.apply(null, Array(total)).map((x, i) => {
          return <Dot key={i} selected={index === i} />
        })
      }</div>
    )
  }
}

export default IndicatorDots