import { Row, Col } from "antd";
import TemplateCard from "../cards/TemplateCard";
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { GhostButton, Div } from "../customComponents";

export default ({ cloudUtils, profile, templates, admin }) => {
  let navigate = useNavigate();

  let extendHeight = "0";
  if (admin && templates.length) {
    extendHeight = "calc(100vh - 110px)";
  } else if (!admin && !profile.onboarding && templates.length) {
    extendHeight = "calc(100vh - 60px)";
  }

  const servicesButton = {
    width: "100%",
    borderRadius: 5,
    padding: "48px 0",
    margin: "16px 0 12px",
    "box-shadow": "rgb(0 0 0 / 25%) 0px 4px 4px"
  };

  const servicesTitle = {
    "font-size": "24px",
    "line-height": "2.6rem"
  };

  // {(admin || (!admin && !profile.onboarding)) &&
  return (
    <Row gutter={[0, 8]} style={{paddingBottom: 16, maxWidth: 480}}>
        {Object.values(templates).length ? Object.values(templates).map((temp) => {
          return (
            <TemplateCard
              onClick={() => navigate(`/${profile.id}/details/${temp.id}`)}
              cloudUtils={cloudUtils}
              id={temp.id}
              service={temp.purchase ? "Buy" : "Book"}
              key={"template" + temp.id}
              admin={admin}
              {...temp}
              profile={profile}
            />
          )})
        : null}
        {/* ( <Info messages={[
            "This is the page displayed for your distinct service offerings. More granular packages can be setup within individual services.",
            // "The order tracking button will allow you to connect with sellers you've previously booked.",
            "You must add your billing account for services to display to customers. This is required for money to be transferred into your account.",
            "Create your first service with the button below and start increasing your bookings.",
            ]}/>
        ) */}
      {admin && (
        <Div
          style={servicesButton}
          onClick={() => navigate(`/${profile.id}/add-template`)}
        >
          <Row gutter={[0, 8]} style={{cursor: 'pointer'}}>
            <Col span={24}>
              <GhostButton icon={<PlusCircleOutlined/>} size="large"/>
            </Col>
            <Col span={24} style={servicesTitle}>
              Add Service
            </Col>
          </Row>
        </Div>
      )}
    </Row>
  );
};
