import { Checkbox } from 'antd';
import Input from '../elements/Input'

const CheckboxInput = ({ soquote, setTotal, displayText=null, ...rest  }) => {

    const updatePrice = (checked, price) => {
        if (checked){
            setTotal(prev => prev + parseFloat(price))
        } else {
            setTotal(prev => prev - parseFloat(price))
        }
    }

    return (
        <Input displayText={displayText} {...rest} style={{marginBottom: 0}}>
            <Checkbox.Group >
                {soquote.filter(([id, {count}]) => !count).map(([id, {feature, price}]) => <><Checkbox onChange={(e) => updatePrice(e.target.checked, price)} value={id} style={{display: 'inherit!important', marginBottom: 16}} >{feature + ' - $' + price}</Checkbox><br/></>)}
            </Checkbox.Group>
        </Input>
    )
};

export default CheckboxInput