import {
  Form,
  Upload,
  message,
  Rate,
  Affix,
  Modal,
  Table,
  Typography,
} from "antd";
import NumberInput from "../inputs/NumberInput";
import {
  MessageOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Info from "../elements/Info";
import Loader from "../elements/Loader";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import BigTextInput from "../inputs/BigTextInput";
import Submit from "../inputs/Submit";
import qs from "query-string";
import TextInput from "../inputs/TextInput";
import Appointment from "../inputs/Appointment";
import {
  BigButton,
  GhostButton,
  Label,
  RegularButton,
  BigHeader,
  SubHeader,
  Div,
  DangerButton,
} from "../customComponents";
import Layout from "../elements/Layout";

const { confirm } = Modal;
const { Text } = Typography;

const StatusPage = ({ message, setTitle }) => {
  setTitle();
  return Array.isArray(message) ? (
    <Info messages={message} />
  ) : (
    <Info messages={[message]} />
  );
};

const ConfirmTerms = ({
  contract,
  template,
  profile,
  cloudUtils,
  setTitle,
  payment,
}) => {
  const location = useLocation();
  let { profileId, contractId } = useParams();
  let { id } = qs.parse(location.search);

  let [button, setButton] = useState(false);
  let toCheckout = cloudUtils.getFunction("toCheckout");
  const [loading, setLoading] = useState(false);

  const acceptFn = () => {
    setLoading(true);
    const busy = (profile.busy || []).filter(({ end }) => end < Date.now());
    if (contract.busy) {
      busy.push({ start: contract.busy[0], end: contract.busy[1] });
      cloudUtils
        .updateDocument("profile", profileId, { busy }, "UPDATE")
        .then(() => {});
    }
    setLoading(false);
    cloudUtils
      .updateDocument(
        "contract",
        contractId,
        { status: contract.price ? "PAYMENT" : "ACCEPTED" },
        "UPDATE"
      )
      .then(() => {
        window.location.reload();
      });
  };

  const checkoutFn = (elseAction) => {
    setButton(true);
    setLoading(true);
    toCheckout({
      name: template.name,
      amount: contract.price,
      currency: template.price.currency.toLowerCase(),
      profileId,
      contractId,
      stripeId: profile.stripe,
      customerEmail: contract.customer.email,
      checkout: contract.checkout,
    }).then(({ data }) => {
      if (data.error) {
        alert("The offer has been updated, please review.");
        window.location.reload();
      } else if (data.message) {
        acceptFn();
        setLoading(false);
      } else {
        elseAction(data);
        setLoading(false);
      }
    });
  };

  useEffect(async () => {
    if (id == contractId) {
      await cloudUtils.updateDocument(
        "contract",
        contractId,
        { status: "ACCEPTED" },
        "UPDATE"
      );
    }
  }, [id]);

  useEffect(() => {
    if (contract.checkout && profile.stripeId) {
      checkoutFn(() => setButton(false));
    }
  }, [contract, profile]);

  // let rejectEstimate = async () => {
  //   await cloudUtils.updateDocument("contract", contractId, { status: 'DENIED' }, 'UPDATE')
  //   window.location.reload()
  //   // setStatus('DENIED')
  // }

  let dataSource = [];
  if (!template.price.free && template.bundle[contract.bundle]) {
    dataSource.push({
      count: 1,
      title: template.bundle[contract.bundle].title,
      price: `$${template.bundle[contract.bundle].price}`,
      value: parseFloat(template.bundle[contract.bundle].price),
    });
    Object.entries(template.quote).forEach(([key, { price, feature }]) => {
      if (contract.quote.includes(key)) {
        dataSource.push({ count: 1, title: feature, price: `$${price}` });
      }
      if (contract.counts[key]) {
        dataSource.push({
          count: contract.counts[key] / parseFloat(price),
          title: feature,
          price: `$${contract.counts[key]}`,
          value: parseFloat(contract.counts[key]),
        });
      }
    });
  }

  let select = (
    <div align="left">
      {loading && <Loader />}

      <BigHeader>{template.name}</BigHeader>
      <p>{template.longDesc}</p>
      <br />
      <div style={{ display: "flex" }}>
        <b>Invoice Number:</b>
        <p style={{ paddingLeft: 8 }}>{contract.invoice}</p>
      </div>
      <br />

      <div style={{ display: "flex" }}>
        <b>{template.purchase ? "Expected Delivery" : "Booking"}:</b>
        <p style={{ paddingLeft: 8 }}>
          {template.purchase ? contract.time.substring(0, 14) : contract.time}
        </p>
      </div>
      <br />
      <Table
        columns={[
          { title: "Item", dataIndex: "title" },
          { title: "Count", dataIndex: "count" },
          { title: "Price", dataIndex: "price" },
        ]}
        dataSource={dataSource}
        pagination={false}
        bordered
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                Adjustments
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Text>{`$${
                  contract.price -
                  dataSource.reduce(
                    (a, c) => {
                      return a.value + c.value;
                    },
                    { value: 0 }
                  )
                }`}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Text>{`$${contract.price}`}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />

      {payment && !profile.cardPayment ? (
        <Info messages={[profile.paymentInstructions]} />
      ) : (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {contract.price && profile.cardPayment ? (
            <Submit
              margin={16}
              text="Pay"
              disabled={button}
              onClick={() =>
                checkoutFn((data) => (window.location.href = data.url))
              }
            />
          ) : (
            <Submit
              margin={16}
              text="Pay"
              disabled={button}
              onClick={() => acceptFn()}
            />
          )}
        </div>
      )}
    </div>
  );

  let pay = (
    <>
      {loading && <Loader />}

      {profile.cardPayment ? (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Info messages={["Your checkout session is in progress."]} />
          <RegularButton
            shape="round"
            type="dashed"
            style={{ margin: 8 }}
            disabled={button}
            onClick={() =>
              checkoutFn((data) => (window.location.href = data.url))
            }
          >
            {" "}
            Accept & Pay{" "}
          </RegularButton>
        </div>
      ) : (
        <Info
          messages={[
            `Once ${profile.name} confirms receipt of payment, you will be able to leave them a review here.`,
            "Payment Instructions:",
            profile.paymentInstructions,
          ]}
        />
      )}
    </>
  );

  setTitle(payment ? "Payment In Progress" : "Invoice");
  return payment ? pay : select;
};

const AcceptPage = ({
  template,
  profileId,
  contractId,
  contract,
  cloudUtils,
  payment,
  complete,
  admin = false,
}) => {
  let [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tracking, setTracking] = useState();

  useEffect(async () => {
    let paymentConfirmation = cloudUtils.getFunction("paymentConfirmation");
    return await paymentConfirmation({
      profileId,
      paymentId: contractId,
      paymentType: "contract",
    });
  }, []);

  let addReview = (values) => {
    setLoading(true);
    cloudUtils
      .updateDocument(
        "contract",
        contractId,
        {
          "customer.rating": values.rating || false,
          "customer.comment": values.comment || false,
          status: "COMPLETE",
        },
        "UPDATE"
      )
      .then(() => {
        window.location.reload();
      });
  };

  const fileUpload = async ({ onError, onSuccess, file }) => {
    if (file) {
      let genPath = contract.template + "/" + contractId + "/" + file.name;

      let filePath =
        contract.template +
        "/" +
        contractId +
        "/final/" +
        "/" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();

      try {
        const data = await cloudUtils.uploadFileWithoutOriginFileObj(
          genPath,
          file
        );
        await cloudUtils.uploadFileWithoutOriginFileObj(filePath, file);
        const updateObj = { status: "COMPLETE" };
        if (tracking) {
          updateObj.tracking = tracking;
        }
        await cloudUtils.updateDocument(
          "contract",
          contractId,
          updateObj,
          "UPDATE"
        );
        onSuccess(null, data);
        window.location.reload();
      } catch (e) {
        onError(e);
      }
    }
  };

  function refund() {
    confirm({
      title: "Request Refund?",
      content: `
        To a request a refund, please select 'Yes' below. Our team at SoCo will process this request and get back to you as soon as possible. 
        Contracts are deemed completed 15 days after the seller uploads proof if no confirmation is provided, at which point refunds will no longer be available. 
      `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cloudUtils
          .getFunction("refund")({
            contractId,
            profileId,
            customerEmail: contract.customer.email,
            customerName: contract.customer.name,
          })
          .then(({ data }) => {
            console.log(data);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  let showReview =
    !admin &&
    !(contract.customer.rating || contract.customer.comment) &&
    !submitted;
  let showRefund = !admin && contract.status == "COMPLETE" && !submitted;

  let adminObject;
  if (payment) {
    adminObject = (
      <Submit
        text="Confirm Payment"
        onClick={async () => {
          setLoading(true);
          await cloudUtils.updateDocument(
            "contract",
            contractId,
            { status: "ACCEPTED" },
            "UPDATE"
          );
          window.location.reload();
        }}
      />
    );
  } else if (complete) {
    adminObject = (
      <Info
        messages={[
          "This contract has been completed! It has been automatically added to your portfolio.",
        ]}
      />
    );
  } else {
    adminObject = (
      <div align="center" style={{ margin: "32px" }}>
        {template.purchase ? (
          <TextInput
            label="tracking"
            displayText="Tracking Code"
            onChange={(e) => {
              setTracking(e.target.value);
            }}
          />
        ) : null}

        <Info
          align="center"
          messages={[
            "To complete this contract, upload an image or clip of your final product.",
            "This will be used in the reviews section of your service.",
          ]}
        />

        <Upload
          customRequest={fileUpload}
          className="upload-list-inline"
          accept="image/*,video/*,audio/*"
        >
          <BigButton>Mark Complete</BigButton>
        </Upload>
      </div>
    );
  }

  return (
    <>
      {loading && <Loader />}

      {contract.price ? (
        <Info messages={["Price:", "$" + contract.price]} />
      ) : null}

      <Div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Label style={{ paddingRight: 4 }}>Scheduled Time:</Label>
        <Label style={{ fontWeight: "600" }}>
          {template.purchase ? contract.time.substring(0, 14) : contract.time}
        </Label>
        <br />
      </Div>

      {admin ? adminObject : null}

      {!admin && contract.tracking ? (
        <Div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Label style={{ paddingRight: 4 }}>Tracking Code:</Label>
          <Label style={{ fontWeight: "600" }}>{contract.tracking}</Label>
          <br />
        </Div>
      ) : null}

      {showReview && (
        <Form className="soco-form" onFinish={addReview}>
          <SubHeader>Provide Your Review</SubHeader>
          <Form.Item name="rating">
            <Rate allowHalf />
          </Form.Item>
          <BigTextInput label="comment" />
          <Submit text="Submit Review" />
        </Form>
      )}
      {showRefund && (
        <DangerButton style={{marginBottom: 30}} onClick={refund}>Request Refund</DangerButton>
      )}
    </>
  );
};

const AddEstimate = ({
  template,
  contract,
  cloudUtils,
  setTitle,
  reviewed,
  profileId,
  profile,
}) => {
  let { contractId } = useParams();
  // let [template, setTemplate] = useState({
  //   price: {currency: 'CAD'},
  //   time: {unit: 'Days'},
  //   survey: {},
  //   quote: {},
  //   bundle: {}
  // })
  let [survey, setSurvey] = useState([]);
  let [quote, setQuote] = useState([]);
  let [bundle, setBundle] = useState({});
  let [invalid, setInvalid] = useState(false);
  let [day, setDay] = useState(
    moment(contract.time.substring(0, 21), "MMM Do, YYYY (h:mm a)")
  );
  const [loading, setLoading] = useState(false);
  const [editTime, editTimeBtn] = useState(false);
  let reviewEmail = cloudUtils.getFunction("reviewEmail");

  useEffect(() => {
    let newSurvey = [];
    let newQuote = [];
    if (template) {
      Object.entries(contract.survey).forEach(([sid, val]) => {
        let question = template.survey[sid];
        if (question && question.type == "text") {
          newSurvey.push("Q: " + question.question);
          newSurvey.push("A: " + val);
        }
      });
      setSurvey([...newSurvey]);

      if (contract.quote) {
        contract.quote.forEach((sid) => {
          let addon = template.quote[sid];
          if (addon) {
            newQuote.push(addon.feature + " - $" + addon.price);
          }
        });
        setQuote([...newQuote]);
      }

      if (contract.counts) {
        Object.entries(contract.counts).forEach(([sid, val]) => {
          let addon = template.quote[sid];
          if (addon) {
            newQuote.push(
              `${addon.feature} - ${val / parseFloat(addon.price)} x $${
                addon.price
              }`
            );
          }
        });
        setQuote([...newQuote]);
      }

      if (template.bundle && contract.bundle) {
        setBundle(template.bundle[contract.bundle]);
      }
    }
  }, [template]);

  let submitEstimate = (values) => {
    if (invalid) {
      alert("Please select a valid date!");
      return;
    }
    const t2 = moment(day).add(template.duration, "minutes");
    let invoice = new Date().toISOString().slice(5, 16).replace(/[T:-]/g, "");
    invoice = `${contractId.slice(0, 4)}-${invoice}`;

    const reviewFn = (status) => {
      setLoading(true);
      cloudUtils
        .updateDocument(
          "contract",
          contractId,
          {
            status: template.price.free ? "ACCEPTED" : "REVIEWED",
            price: template.price && template.price.free ? 0 : values.price,
            currency: template.price.currency,
            unit: template.purchase ? template.time.unit : false,
            time: template.purchase
              ? day.format("MMM Do, YYYY (h:mm a)")
              : day.format("MMM Do, YYYY (h:mm a)") +
                " to " +
                t2.format("MMM Do, YYYY (h:mm a)"),
            busy: template.purchase ? false : [day.valueOf(), t2.valueOf()],
            invoice,
          },
          "UPDATE"
        )
        .then(() => {
          if (status == "PENDING") {
            reviewEmail({
              name: template.name,
              profileId,
              contractId,
              customerEmail: contract.customer.email,
              customerName: contract.customer.name,
            }).then(() => {
              window.location.reload();
              // setStatus('PAYMENT')
            });
          } else {
            setLoading(false);
            alert("Your contract terms have been updated.");
          }
        });
    };

    cloudUtils.getDocumentB("contract", contractId).then(async (doc) => {
      let status = doc.data().status;
      if (["PENDING", "REVIEWED"].includes(status)) {
        reviewFn(status);
      } else {
        alert(
          "Your contract has already been accepted, no further updates can be made."
        );
        window.location.reload();
      }
    });
  };

  setTitle(reviewed ? "Estimate Added" : "Add Estimate");

  let provide = (
    <div>
      {loading && <Loader />}
      <Div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Text>{contract.customer.email}</Text>
        <br />
        <BigHeader>{template.name}</BigHeader>
        <Text style={{ fontStyle: "italic" }}>{template.longDesc}</Text>
      </Div>
      <br />

      {reviewed && (
        <Info
          messages={[
            "Your customer has been notified of your terms, and has been prompted for payment.",
            "You can make updates to your terms at any point before your customer accepts the agreement.",
          ]}
        />
      )}

      {quote.length > 0 && (
        <>
          <SubHeader align="left">Requests:</SubHeader>
          <Info indent messages={quote} />
        </>
      )}

      {bundle && bundle.title ? (
        <>
          <SubHeader align="left">Selected Package:</SubHeader>
          <Info
            indent
            messages={[
              `${bundle.title} - $${bundle.price}`,
              bundle.description,
            ]}
          />
        </>
      ) : null}

      {survey.length > 0 && (
        <>
          <SubHeader align="left">Questionaire Responses:</SubHeader>
          <Info indent messages={[...survey]} />
        </>
      )}

      <Form
        align="left"
        initialValues={{
          price: contract.price,
          time: contract.time
            ? contract.time
                .split(" to ")
                .map((x) => moment(x, "MMM Do, YYYY (h:mm a)"))
            : null,
        }}
        onFinish={submitEstimate}
      >
        {template.price && template.price.free ? null : (
          <NumberInput
            displayText={`Set price (${template.price.currency})`}
            label="price"
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        )}
        <SubHeader>
          {template.purchase ? "Estimated Delivery" : "Appointment Time"}
        </SubHeader>
        {editTime ? (
          <Appointment
            day={day}
            setInvalid={setInvalid}
            setDay={setDay}
            duration={template.duration}
            openHours={profile.openHours}
            busy={profile.busy}
            showTime={!template.purchase}
            closedDays={profile.closedDays}
          />
        ) : (
          <Text>
            {" "}
            {template.purchase
              ? contract.time.substring(0, 14)
              : contract.time}{" "}
            <GhostButton
              icon={<EditOutlined />}
              type="text"
              onClick={() => editTimeBtn(true)}
            />{" "}
          </Text>
        )}
        <Submit
          text={
            template.price.free
              ? "Accept"
              : reviewed
              ? "Update Invoice"
              : "Generate Invoice"
          }
        />
      </Form>
    </div>
  );

  return provide;
};

export default ({ cloudUtils, user }) => {
  let { profileId, contractId } = useParams();
  const navigate = useNavigate();
  let [contract, setContract] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  let [template, setTemplate] = useState({
    name: "Loading...",
    price: { currency: "CAD" },
    time: { unit: "Days" },
    survey: {},
    bundle: {},
    quote: {},
  });
  const [profile, setProfile] = useState({
    name: "Loading...",
    handle: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    theme: "dark",
  });

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
  }, [profile]);

  const [pic, setPic] = useState(profile.pic);

  let [title, setTitle] = useState(false);
  let [basePath, setBasePath] = useState(false);
  let [fileList, setFileList] = useState(false);

  const goBack = () => navigate(admin ? `/${profileId}/orders` : "/track");

  const fileUpload = async ({ onError, onSuccess, file }) => {
    if (file) {
      let filePath = basePath + file.name;
      try {
        const data = await cloudUtils.uploadFileWithoutOriginFileObj(
          filePath,
          file
        );
        onSuccess(null, data);
      } catch (e) {
        onError(e);
      }
    }
  };

  const fileDelete = async (info) => {
    let filePath = basePath + info.name;
    const data = await cloudUtils.deleteFile(filePath).then(() => {
      message.success(`${info.name} file deleted successfully`);
    });
  };

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);

    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList(info.fileList);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status == "removed") {
      fileDelete(info.file);
    }
  };

  function ShowFiles() {
    if (fileList) {
      return <></>;
    } else {
      return (
        <>
          <div>Loading Uploaded Files...</div>
        </>
      );
    }
  }

  useEffect(() => {
    cloudUtils.getDocument("contract", contractId, setContract);
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, []);

  useEffect(() => {
    if (profile.pic) {
      cloudUtils.getDownloadURL(profile.pic).then((src) => setPic(src));
    }
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
  }, [profile]);

  useEffect(() => {
    if (contract) {
      cloudUtils.getDocument("template", contract.template, setTemplate);

      setBasePath(`${contract.template}/${contractId}/`);
      cloudUtils.getUploadedFiles(
        `${contract.template}/${contractId}/`,
        setFileList
      );
    }
  }, [contract]);

  const toConversation = () =>
    navigate({
      pathname: "/" + profileId + "/offer/" + contractId + "/conversation",
      state: { ...profile },
    });

  let viewer = admin ? "worker" : "customer";

  let stateMap = {
    worker: {
      PENDING: (
        <AddEstimate
          template={template}
          profileId={profileId}
          profile={profile}
          contract={contract}
          cloudUtils={cloudUtils}
          setTitle={setTitle}
        />
      ),
      REVIEWED: (
        <AddEstimate
          template={template}
          profileId={profileId}
          profile={profile}
          contract={contract}
          cloudUtils={cloudUtils}
          setTitle={setTitle}
          reviewed
        />
      ),
      DENIED: (
        <StatusPage
          message="This offer has been declined."
          setTitle={() => setTitle(template.name)}
        />
      ),
      PAYMENT: (
        <AcceptPage
          template={template}
          profileId={profileId}
          contractId={contractId}
          contract={contract}
          cloudUtils={cloudUtils}
          admin
          payment
        />
      ),
      ACCEPTED: (
        <AcceptPage
          template={template}
          profileId={profileId}
          contractId={contractId}
          contract={contract}
          cloudUtils={cloudUtils}
          admin
        />
      ),
      COMPLETE: (
        <AcceptPage
          template={template}
          profileId={profileId}
          contractId={contractId}
          contract={contract}
          cloudUtils={cloudUtils}
          admin
          complete
        />
      ),
    },
    customer: {
      PENDING: (
        <StatusPage
          message={`${profile.name} has been notified of your order and is now reviewing the terms. Click the chat icon to connect with ${profile.name} and discuss your order.`}
          setTitle={() => setTitle(template.name)}
        />
      ),
      REVIEWED: (
        <ConfirmTerms
          profileId={profileId}
          contract={contract}
          template={template}
          profile={profile}
          cloudUtils={cloudUtils}
          setTitle={setTitle}
        />
      ),
      DENIED: (
        <StatusPage
          message={`This offer has been declined. Visit soco.market/${profile.handle} to start another order with ${profile.name}.`}
          setTitle={() => setTitle(template.name)}
        />
      ),
      PAYMENT: (
        <ConfirmTerms
          profileId={profileId}
          contract={contract}
          template={template}
          profile={profile}
          cloudUtils={cloudUtils}
          setTitle={setTitle}
          payment
        />
      ),
      ACCEPTED: (
        <AcceptPage
          template={template}
          profileId={profileId}
          contractId={contractId}
          contract={contract}
          cloudUtils={cloudUtils}
        />
      ),
      COMPLETE: (
        <AcceptPage
          template={template}
          profileId={profileId}
          contractId={contractId}
          contract={contract}
          cloudUtils={cloudUtils}
        />
      ),
    },
  };

  return (
    <Layout
      title={admin ? contract.customer.name : "Success!"}
      onBack={goBack}
      avatar={{ src: pic }}
      extra={[<MessageOutlined onClick={toConversation} />]}
      theme={profile.theme}
    >
      {contract ? stateMap[viewer][contract.status] : null}
      <center style={{ padding: "0 32px" }}>
        <Upload
          accept="image/*,video/*,audio/*"
          name="file"
          customRequest={fileUpload}
          onChange={handleUploadChange}
          multiple="true"
          fileList={fileList ? fileList : null}
        >
          <RegularButton icon={<UploadOutlined />}>Add Files</RegularButton>
        </Upload>
        <ShowFiles />
      </center>
    </Layout>
  );
};
