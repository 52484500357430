import { Form } from 'antd';
import { useEffect } from 'react';
import { Label, Radio } from '../customComponents'

const RadioInput = ({ soquote, setBase, setInitial = () => {}, displayText, label, required = true, validators = [], style, ...rest  }) => {

    const updatePrice = (price) => {
        setBase(() => parseFloat(price))
    }

    let basePackage = soquote.filter(x => x[1].base)
    let addPackage = soquote.filter(x => !x[1].base)

    useEffect(() => {
        if (!addPackage.length){
            setInitial(basePackage[0][1].title)
            updatePrice(basePackage[0][1].price)
        } else {
            updatePrice(addPackage[0][1].price)
        }
    }, [])

    
    const inputBoxStyle = {
        borderRadius: 5,
        marginBottom: 16,
    }

    return (
        <Form.Item
            label={displayText ? <Label>{displayText}</Label> : null}
            name={label}
            initialValue={addPackage.length ? addPackage[0][0] : basePackage[0][0]}
            rules={[
                {
                    required,
                    message: `Please select a package`
                },
                ...validators
            ]}
            style={style}
        >
            <Radio.Group defaultValue={addPackage.length ? addPackage[0] : basePackage[0]}>
                {addPackage.map(([id, {title, price, description, base}]) => <><Radio onChange={(e) => {updatePrice(price); setInitial(base)}} value={id} style={inputBoxStyle} {...rest}>{title + ' - $' + price}<br/><span style={{whiteSpace: 'pre-wrap'}}>{description}</span></Radio><br/></>)}
                {basePackage.map(([id, {title, price, description, base}]) => <><Radio onChange={(e) => {updatePrice(price); setInitial(title)}} value={id} style={inputBoxStyle} {...rest}>{title + ' - $' + price}<br/><span style={{whiteSpace: 'pre-wrap'}}>{description}</span></Radio><br/></>)}
            </Radio.Group>
        </Form.Item>
    )
    // Please input a value ${displayText && ('for ' + displayText.lowerCase())}
};

export default RadioInput