import { Input } from 'antd';
import { usePlacesWidget } from "react-google-autocomplete";
import { useState } from 'react';
import { Label } from '../customComponents';

const LocationInput = ({ inputRef, setPlace, setAddress }) => { 
    const [chosen, setChosen] = useState(false)
    const { ref } = usePlacesWidget({
        apiKey: 'AIzaSyByfE_HBTdSyY2C-ruNE0VNZYFOGTK8viQ',
        options: {
            types: ["(cities)"]
        },
        onPlaceSelected: (pl) => {
            inputRef.current.setValue(pl?.formatted_address)
            setChosen(true)
            setPlace(pl?.place_id)
            setAddress(pl?.formatted_address)
        },
    });
    return (<>
        <label className='ant-col ant-form-item-label' style={{width: '100%'}} align="left"><Label>City</Label></label>
        <Input
            onBlur={() => {if (!chosen) { inputRef.current.setValue(); setPlace(); setAddress() }}}
            onChange={() => setChosen(false)}
            style={{borderRadius: 5, marginBottom: 22}}
            ref={(c) => {
                inputRef.current = c;
                if (c) ref.current = c.input;
            }}
        />
    </>)
}

export default LocationInput