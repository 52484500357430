import { useState, useEffect } from "react";
import TemplateForm from "../forms/TemplateForm";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Layout from "../elements/Layout";

export default ({ cloudUtils }) => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [profile, setProfile] = useState({ theme: "dark", socials: {} });
  const [uploaded, setUploaded] = useState(false);
  const [templateId, _] = useState(uuidv4());

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId]);

  useEffect(() => {
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
  }, [profile]);

  const goBack = () => navigate(-1);

  const [loading, setLoading] = useState(false);

  return (
    <Layout title="Add Service" onBack={goBack} theme={profile.theme}>
      <TemplateForm
        cloudUtils={cloudUtils}
        templateId={templateId}
        setLoading={setLoading}
      />
    </Layout>
  );
};
