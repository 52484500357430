import { Checkbox } from 'antd';
import Input from '../elements/Input'

const BusyDays = ({ displayText=null, ...rest  }) => {
    const DOW = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    return (
        <Input label="closedDays" displayText={displayText} {...rest} style={{marginBottom: 0}}>
            <Checkbox.Group align="center">
                {DOW.map((dow, id) => <Checkbox value={id} style={{display: 'inherit!important', marginBottom: 16}} >{dow}</Checkbox>)}
            </Checkbox.Group>
        </Input>
    )
};

export default BusyDays