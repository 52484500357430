import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
// import * as fir from "firebase/firestore"

import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";

const ACTIVE_ENV = 'prod'

const envs = {
  beta: {
    apiKey: "AIzaSyCzy94DDzeOWWjcuquadMx7L2Fr-EFD6qw",
    authDomain: "socobeta-c6588.firebaseapp.com",
    projectId: "socobeta-c6588",
    storageBucket: "socobeta-c6588.appspot.com",
    messagingSenderId: "554853388978",
    appId: "1:554853388978:web:2f55cfd1d03e245f35afab",
    measurementId: "G-K8DQV39Z5T"
  },
  dev: {
    apiKey: "AIzaSyByfE_HBTdSyY2C-ruNE0VNZYFOGTK8viQ",
    authDomain: "socodev-fb036.firebaseapp.com",
    projectId: "socodev-fb036",
    storageBucket: "socodev-fb036.appspot.com",
    messagingSenderId: "281013609205",
    appId: "1:281013609205:web:ecafea615f20514a149bbc",
    measurementId: "G-0CCR7KYC2L"
  },
  prod: {
    apiKey: "AIzaSyDdtJQtoDDHEHRHD7-Q1d4sO4PV-PP4-no",
    authDomain: "soco-aa9d5.firebaseapp.com",
    projectId: "soco-aa9d5",
    storageBucket: "soco-aa9d5.appspot.com",
    messagingSenderId: "1087165861532",
    appId: "1:1087165861532:web:385d19ec9a189397602759",
    measurementId: "G-53G5VER34F"
  }
}

firebase.initializeApp(envs[ACTIVE_ENV]);

var db = firebase.firestore()
var cs = firebase.storage()
var fn = firebase.functions()
var au = firebase.auth

ReactDOM.render(
  <React.StrictMode>
    <App firestore={db} storage={cs} functions={fn} auth={au}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
