import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import SettingsForm from "../forms/SettingsForm";
import TipForm from "../forms/TipForm";
import EventForm from "../forms/EventForm";
import PurchaseForm from "../forms/PurchaseForm";
import Chat from "../elements/Chat";
import EventList from "../lists/EventList";
import { Button } from "antd";
import { SaveOutlined, LoadingOutlined, LogoutOutlined } from "@ant-design/icons";
import { PrimaryIcon, DangerButton, DangerGhostButton } from "../customComponents";

export default ({ cloudUtils, type, user, auth }) => {
  let { profileId } = useParams();
  const [eventHosting, setEventHosting] = useState(false);
  const [save, setSave] = useState(true);
  const [spinner, startSpinner] = useState(false);

  const signOut = () => {
    // setLoading(true)
    auth().signOut()
    .then(() => {
    //   setLoading(false)
      console.log('Signed Out');
      window.location = '//info.soco.market'
    }).catch((error) => {
      console.error('Sign Out Error', error);
    //   setLoading(false)
    });
  }

  let hb = spinner ? (
    [
      (<PrimaryIcon
        icon={<LoadingOutlined/>}
      />),
      (<DangerGhostButton
        icon={<LogoutOutlined />}
        onClick={signOut}
      />)
    ]
  ) : (
    [
      (<PrimaryIcon
        icon={<SaveOutlined/>}
        onClick={() => alert("Your changes are saved automatically")}
      />),
      (<DangerGhostButton
        icon={<LogoutOutlined />}
        onClick={signOut}
      />)
    ]
  );

  const navigate = useNavigate();
  const [uploaded, setUploaded] = useState(false);

  const goHome = () => navigate(-1);

  const [profile, useProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    theme: "dark",
    links: [],
    transactional: false,
    authId: ":(",
    onboarding: false,
    events: [],
  });

  let [loading, setLoading] = useState(true);
  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);
  const [pic, setPic] = useState(profile.pic);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, useProfile);
  }, []);

  useEffect(() => {
    if (profile.pic) {
      cloudUtils.getDownloadURL(profile.pic).then((src) => setPic(src));
    }
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
  }, [profile]);

  const pageMap = {
    settings: admin ? (
      <SettingsForm
        auth={auth}
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={profile}
        profileId={profileId}
        setSave={setSave}
        startSpinner={startSpinner}
      />
    ) : null,
    tip: (
      <TipForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={profile}
        profileId={profileId}
      />
    ),
    event: eventHosting ? (
      <>
        <div align="left" style={{ marginBottom: 24 }}>
          <Button
            size="large"
            shape="round"
            onClick={() => setEventHosting(false)}
            block
          >
            {" "}
            View Events{" "}
          </Button>
        </div>
        <EventForm cloudUtils={cloudUtils} setLoading={setLoading} />
      </>
    ) : (
      <>
        <EventList
          profile={profile}
          cloudUtils={cloudUtils}
          setLoading={setLoading}
          setEventHosting={setEventHosting}
        />
      </>
    ),
    purchase: (
      <PurchaseForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        profile={{ id: profileId, ...profile }}
      />
    ),
    chat: <Chat cloudUtils={cloudUtils} profile={profile} user={user} />,
  };

  const titleMap = {
    settings: "Settings",
    tip: `Tip ${profile.name}!`,
    event: "Events",
    purchase: "Purchase Service",
    chat: `${profile.name}`,
  };

  const avatarMap = {
    settings: false,
    tip: true,
    event: false,
    purchase: false,
    chat: true,
  };

  const goBackMap = {
    settings: save
      ? goHome
      : () => alert("Please fill out all required fields"),
    purchase: () => navigate(-1),
    chat: () => navigate(-1),
  };

  return (
    <Layout
      page={type == "event" ? "Menu" : null}
      pageType={type == "event" ? "Event" : null}
      type={type}
      admin={admin}
      headerButtons={type == "settings" ? hb : null}
      title={titleMap[type]}
      onBack={goBackMap[type] || goHome}
      loading={loading}
      theme={profile.theme}
      avatar={avatarMap[type] ? { src: pic } : null}
    >
      {pageMap[type]}
    </Layout>
  );
};

{
  /* {(profile.events && profile.events.length > 0) ? <Divider displayText='Host an event'/> : <h3>Host an event</h3>} */
}
