import { Form, Switch } from 'antd';
import { Label } from '../customComponents';
const ToggleInput = ({ displayText, label, state, setState, checked="Yes", unChecked="No"   }) => (
    <Form layout='inline' >
        <Form.Item name={label} label={<Label>{displayText}</Label>}  wrapperCol={{ span: 14, offset: 4 }} style={{'flex-wrap': 'wrap'}}>
            <Switch checked={state}  onChange={() => setState(!state)} checkedChildren={checked} unCheckedChildren={unChecked} />
        </Form.Item>
    </Form>
)

export default ToggleInput