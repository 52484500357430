import { StyledFirebaseAuth } from 'react-firebaseui';
import { useState } from 'react';
import Loader from '../elements/Loader';
import Info from '../elements/Info';
import { SubHeader } from '../customComponents';

const LoginPage = ({ cloudUtils, user, auth }) => {
    const [loading, setLoading] = useState(true)
    let uiConfig = {
        callbacks: {
          signInFailure: function(error){
            console.log(error)
          },
          signInSuccessWithAuthResult: (authResult) => {
            cloudUtils.login(
              authResult.user.uid, 
              authResult.user.displayName,
              authResult.user.email,
            ).then((id) => {
              window.location = '/' + (id ? id : authResult.user.uid)
            })
          },
          uiShown: function() {
            setLoading(false)
          }
        },
        signInFlow: 'popup',
        signInOptions: [
          auth.GoogleAuthProvider.PROVIDER_ID,
          auth.EmailAuthProvider.PROVIDER_ID,
        ],
      };

    return (
        <div className='login-page'>
            {loading && <Loader />}

            <div align="center">
              <SubHeader>Login / Signup </SubHeader>
              <Info align="center" messages={['Access your account with your email or google account.','If you have not yet created an account, you will be prompted to after logging in.']}/>
              <div style={{marginTop: '40px'}}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
              </div>
            </div>

        </div>
    )
};

export default LoginPage