import { Select } from "antd";
import tags from "../utils/tags";
import { useEffect, useState } from "react";

const { Option, OptGroup } = Select

export default ({selected, setSelected}) => {
  const [options, setOptions] = useState()

  useEffect(() => {
    setOptions(
      <Select 
        mode="multiple"
        style={{width: '100%'}}
        value={selected}
        onChange={(values) => setSelected(values.slice(0, 3))}
      >
        {Object.entries(tags).map(([group, values]) => 
          (
            <OptGroup label={group}>
              {values.map(({label, value}) => <Option value={value}>{label}</Option>)}
            </OptGroup>
          )
        )}
      </Select>
    );
  }, [selected]);

  return options ? options : <></>

}