export const charLimitValidator = (displayText, textValue, limit) => {
  return {
    validator: () => {
      if (textValue.length <= limit) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
    message: displayText + " must be less than " + limit + " characters",
    validateTrigger: "onSubmit",
  };
};

export const phoneValidator = () => {
  return {
    validator: (_, value) => {
      if ([0, 10].includes(String(value).length) || !value) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
    message: "Phone number must have 10 digits",
    validateTrigger: "onChange",
  };
};

export const round15 = () => {
  return {
    validator: (_, value) => {
      if (parseFloat(value) % 15 != 0) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "Must be a multiple of 15 minutes",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const pricePositive = (isRange, min, max) => {
  return {
    validator: (_, value) => {
      if (isRange) {
        if (
          !/^[0-9]*(\.[0-9]{1,2})?$/.test(min) ||
          !/^[0-9]*(\.[0-9]{1,2})?$/.test(max) ||
          parseFloat(min) < 1
        ) {
          return Promise.reject();
        }
      } else {
        if (!/^[0-9]*(\.[0-9]{1,2})?$/.test(value) || parseFloat(value) < 1) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
    message:
      "Must enter at least $1. Otherwise, you can set this service as free.",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const timePositive = (isRange, min, max, value) => {
  return {
    validator: () => {
      if (isRange) {
        if (
          !/^[0-9]*$/.test(min) ||
          !/^[0-9]*(\.[0-9]{1,2})?$/.test(max) ||
          parseInt(min) < 1
        ) {
          return Promise.reject();
        }
      } else {
        if (value && (!/^[0-9]*$/.test(value) || parseInt(value) < 1)) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
    message: "Must be at least 1 day",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const timeMinTest = (min, max) => {
  return {
    validator: () => {
      if (parseInt(min) > parseInt(max)) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "Min must be less than max",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const timeMaxTest = (min, max) => {
  return {
    validator: () => {
      if (parseInt(min) > parseInt(max)) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "Max must be greater than min",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const priceMinTest = (min, max) => {
  return {
    validator: () => {
      if (parseFloat(max) < parseFloat(min)) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "Min must be less than max",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const priceMaxTest = (min, max) => {
  return {
    validator: () => {
      if (parseFloat(max) < parseFloat(min)) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "Max must be greater than min",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const bundleLength = (existingBd, pricePackage) => {
  return {
    validator: (_, bundle) => {
      if (!bundle || bundle.length + Object.keys(existingBd).length < 1) {
        console.log("not valid bundle");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: "At least one package required",
    required: pricePackage,
  };
};

export const emailOrderValidator = (contracts, email) => {
  return {
    validator: () => {
      let verified = false;
      contracts.forEach((data) => {
        if (data.status != "UNVERIFIED" && data.customer != undefined) {
          if (data.customer.email === email) {
            verified = true;
          }
        }
      });
      return verified ? Promise.resolve() : Promise.reject();
    },
    message: "Could not find any orders associated with provide email.",
    required: true,
    validateTrigger: "onSubmit",
  };
};

export const emailValidator = {
  type: "email",
  message: "Email is not valid",
};

export const orderIdValidator = (contracts, orderId, email) => {
  return {
    validator: () => {
      let verified = false;
      contracts.forEach((data) => {
        if (data.status != "UNVERIFIED" && data.customer != undefined) {
          if (data.customer.email === email) {
            if (data.id === orderId) {
              verified = true;
            }
          }
        }
      });

      return verified ? Promise.resolve() : Promise.reject();
    },
    message: "Could not find order for the given email.",
    required: true,
    validateTrigger: "onSubmit",
  };
};

// export const charLimitValidator = (displayText, textValue, limit) => {
//   return {
//     validator: () => {
//       if (textValue.length <= limit) {
//         return Promise.resolve();
//       }
//       return Promise.reject();
//     },
//     message: displayText +' must be less than ' + limit + ' characters',
//     validateTrigger: 'onSubmit'
//   }
// }

// export const pricePositive = {
//   validator: () => {
//       if (!/^[0-9]*(\.[0-9]{1,2})?$/.test(value) || parseFloat(value) < 1){
//       return Promise.reject();
//       }
//       return Promise.resolve()
//   },
//   required: false,
//   validateTrigger: 'onSubmit'
// }

// export const pricePositive = {
//   validator: () => {
//     if (!/^[0-9]*(\.[0-9]{1,2})?$/.test(value) || parseFloat(value) < 1){
//       return Promise.reject();
//     }
//     return Promise.resolve()
//   },
//   message: 'Must be a number above 1',
//   required: true,
//   validateTrigger: 'onSubmit'
// }

// export const pricePositive = {
//   validator: () => {
//     if (!/^[0-9]*(\.[0-9]{1,2})?$/.test(cost) || parseFloat(cost) < 0){
//       return Promise.reject();
//     }
//     return Promise.resolve()
//   },
//   message: 'Must be a number above 0',
//   required: true,
//   validateTrigger: 'onSubmit'
// }

// export const pricePositive = {
//   validator: () => {
//       if (!/^[0-9]*(\.[0-9]{1,2})?$/.test(value) || parseFloat(value) < 1){
//       return Promise.reject();
//       }
//       return Promise.resolve()
//   },
//   message: 'Must be a number above 1',
//   required: true,
//   validateTrigger: 'onSubmit'
// }

// export const timePositive = {
//   validator: () => {
//       if (!/^[0-9]*$/.test(value) || parseInt(value) < 1){
//       return Promise.reject();
//       }
//       return Promise.resolve()
//   },
//   message: 'Must be at least 1 day',
//   required: true,
//   validateTrigger: 'onSubmit'
// }
