import { PageHeader, BigHeader, Div } from "../customComponents";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import Footer from './Footer'
import Loader from "./Loader";
import { ThemeProvider } from "styled-components";

const themes = {
  default: {
    primary: "#3a3960",
    secondary: "#eb9a92",
    accent: "#c8658d",
    danger: "#c8658d",
    background: "#f5f5f5",
    design: "liquid",
  },
  cool: {
    primary: "#253643",
    secondary: "#d8cd80",
    accent: "#8da88d",
    danger: "#c8658d",
    background: "#f5f5f5",
    design: "liquid",
  },
  coffee: {
    primary: "#2f2626",
    secondary: "#9E7676",
    accent: "#fffefd",
    danger: "#c8658d",
    background: "#fffefd",
    design: "block",
  },
  dark: {
    primary: "#2b2b2b",
    secondary: "#b3b3b3",
    accent: "#f3f3f3",
    danger: "#c8658d",
    background: "#f3f3f3",
    design: "block",
  }
}

export default ({
  headerButtons,
  footer,
  headerBody,
  children,
  background = '#f5f5f5',
  theme = 'default',
  back = false,
  loading,
  style,
  title,
  page,
  pageType,
  type,
  admin,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={themes[theme] || themes.default}>
      {page == "Menu" ? (
        <Div style={{height: '100%'}} >
          {loading && <Loader />}
          <div className="menu-body">
            {children}
            {(footer && !admin) ? <Footer/> : null}
          </div>
        </Div>
      ) : (
        <div style={{height: '100%', ...style}}>
          {loading && <Loader />}
          <PageHeader
            onBack={back ? () => navigate(back) : null}
            extra={headerButtons}
            title={
              <BigHeader >
                {title}
              </BigHeader>
            }
            {...rest}
          >
            {headerBody}
          </PageHeader>
          <Div style={{ minHeight: '100vh', padding: type == 'chat' ? "68px 24px 0" : "90px 24px 24px" }}>
            {children}
            {footer ? <Footer/> : null}
          </Div>
        </div>
      )}
    </ThemeProvider>
  );
};
