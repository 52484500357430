import { Form, Input, Tooltip } from 'antd';
import { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { pricePositive } from '../utils/validators'
import { Label } from '../customComponents';

const TextInput = ({ displayText, label, initial = null, required, tooltip, validators = [], style, price, tight, ...rest  }) => {
    const [priceValue, setPriceValue] = useState()

    const inputBoxStyle = {
        borderRadius: 5,
    }

    let newValidators = [...validators]
    if (price){
        newValidators.push(pricePositive(false, 0, 0, priceValue))
    }
    // : <label style={{ color: 'transparent' }}>empty</label>
    return (
        <Form.Item
            className={tight ? 'tight' : null}
            label={displayText ? 
                <Label>{displayText}
                    {tooltip && 
                        <Tooltip title={tooltip}><sup><InfoCircleOutlined style={{fontSize: 12, paddingLeft: 12}}/></sup></Tooltip>
                    }
                </Label>
                : null}
            name={label}
            initialValue={initial}
            rules={[
                {
                    required,
                    message: ``
                },
                ...newValidators
            ]}
            style={style}
        >
            {price 
                ? <Input prefix="$" style={inputBoxStyle} defaultValue={initial} {...rest} onChange={(e) => {setPriceValue(e.target.value)}}/> 
                : <Input  defaultValue={initial} style={inputBoxStyle} {...rest}/>}
        </Form.Item>
    )
    // Please input a value ${displayText && ('for ' + displayText.lowerCase())}
};

export default TextInput