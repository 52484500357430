import React from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";
import TextInput from "../../inputs/TextInput";
import { DangerGhostButton, RegularButton } from "../../customComponents";

function Quote() {
  return (
    <Form.List name="quote" >
      {(fields, { add, remove }) => {
        return (
          <div >
            {fields.map((field, index) => (
              <div key={field.key} style={{marginBottom: 16}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <TextInput tight required={true} placeholder="Item Description" style={{flexGrow: 1, marginBottom: 0, paddingRight: 16}} label={[index, "feature"]} />
                  <TextInput tight required={true} price={true} placeholder="Price" style={{width: 90, marginBottom: 0, paddingRight: 16}} label={[index, "price"]} />
                  <DangerGhostButton onClick={() => remove(field.name)} icon={<CloseCircleOutlined />}/> 
                </div>

                  <Form.Item
                    // label={<label style={{ color }}>Count</label>}
                    name={[index, "count"]}
                    required
                    style={{marginTop: 8}} 
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>Can user order multiple?</Checkbox>
                  </Form.Item>
              </div>
            ))}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Addons
                </RegularButton>
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Quote;
