import { useEffect, useState } from 'react';
import PictureCard from '../elements/PictureCard'

export default ({ name, longDesc, price, bundle, cloudUtils, onClick, serviceImage, admin, profile, id, setSrc, service }) => {
  const [uploaded, setUploaded] = useState(false)
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
    cloudUtils.getUploadedFiles(id, setPortfolio)
    if (serviceImage) {
      cloudUtils.getDownloadURL(serviceImage).then((src) => setSrc(src))
    }
    if (profile.uploaded && profile.theme == 'uploaded') {
      cloudUtils.getDownloadURL(profile.uploaded).then((src) => setUploaded(src))
    }
  }, [])

  const packageKeys = bundle ? Object.keys(bundle) : []
  const packagePrices = packageKeys.length ? packageKeys.map(key => {return bundle[key].price}): [0]

  return (
    <PictureCard 
      admin={admin}
      media={portfolio}
      uploaded={uploaded}
      title={name}
      desc={longDesc}
      price={`${Math.min.apply(null, packagePrices).toFixed(2)} ${price.currency}`}
      free={price.free}
      onClick={onClick}
      service={service}
      theme={profile.theme}
    />
  )

}