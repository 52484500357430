import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "re-carousel";
import IndicatorDots from "./IndictorDots";
import Loader from "./Loader";
import { BigButton, RegularButton } from "../customComponents";
import {
  MenuOutlined,
  SwapRightOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  HomeOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import { Div } from "../customComponents";
import profileImage from "../resources/setUpProfile.svg";
import servicesImage from "../resources/addServices.svg";
import paymentImage from "../resources/setUpPayment.svg";

export default ({ cloudUtils, profile, profileId, setShowWalkthrough }) => {
  document.getElementById("root").style.height = "100%";
  document.getElementById("root").style.backgroundColor = "#f5f5f5";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const goHome = () => navigate(`/${profileId}`);

  function ImageHolder(props) {
    const imageSource = props.src;
    const imageName = props.name;
    const style = props.style;

    const imageStyle = {
      margin: "5rem auto 0px auto",
      ...style,
    };

    return (
      <center>
        <img src={imageSource} alt={imageName} style={imageStyle} />
      </center>
    );
  }

  function TextHolder(props) {
    const message = props.msg;
    const type = props.type;

    const titleStyle = {
      margin: "24px auto 0px auto",
      "font-size": "24px",
    };

    const infoStyle = {
      margin: "1rem 10vw 0px 10vw",
      "font-size": "16px",
    };

    return (
      <center>
        <Div style={type === "title" ? titleStyle : infoStyle}>{message}</Div>
      </center>
    );
  }

  function FlowHolder(props) {
    const flow = props.flow;

    const flowStyle = {
      "margin-top": "0.5rem",
      "font-size": "14px",
    };

    const messageStyle = {
      "margin-left": "1vw",
      "font-size": "14px",
    };

    return (
      <Div style={flowStyle}>
        {flow.beforeIcon}
        {flow.icon}
        <span style={messageStyle}> {flow.message} </span>
      </Div>
    );
  }

  function StepHolder(props) {
    const flows = props.flows;
    const message = props.msg;

    const stepStyle = {
      display: "flex",
      margin: "1rem 10vw 0px 10vw",
      "font-size": "16px"
    };

    const messageStyle = {
      margin: "0.2.5rem 2vw 0px 0px",
      "font-size": "16px",
    };

    return (
      <div style={stepStyle}>
        <Div style={messageStyle}>{message}</Div>
        <div>{flows && flows.map((flow) => <FlowHolder flow={flow} />)}</div>
      </div>
    );
  }

  const flows = [
    {
      beforeIcon: null,
      icon: <MenuOutlined />,
      message: "Menu",
    },
    {
      beforeIcon: <SwapRightOutlined />,
      icon: <SettingOutlined />,
      message: "Setting",
    },
  ];

  const flows2 = [
    {
      beforeIcon: null,
      icon: <MenuOutlined />,
      message: "Menu",
    },
    {
      beforeIcon: <SwapRightOutlined />,
      icon: <PlusCircleOutlined />,
      message: "Add Service",
    },
  ];



  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Carousel widgets={[IndicatorDots]}>
        <Div
          style={{
            height: "calc(100% - 32px)",
            overflow: "auto",
          }}
        >
          <ImageHolder src={profileImage} style={{ height: "40vh" }} />
          <TextHolder msg="Setting Up Profile" type="title" />
          <TextHolder
            msg="Make your profile more attractive to customers by setting up your profile."
            type="info"
          />
          <StepHolder msg="Navigate to: " flows={flows} />
          <StepHolder msg="Add a profile picture, change the theme and links to multiple platforms." />
        </Div>
        <Div
          style={{
            height: "calc(100% - 32px)",
            overflow: "auto",
          }}
        >
          <ImageHolder src={servicesImage} style={{ height: "40vh" }} />
          <TextHolder msg="Adding a Service" type="title" />
          <TextHolder
            msg="Now that your profile is set up, give your customers the option to request your services by creating them."
            type="info"
          />
          <StepHolder msg="Navigate to: " flows={flows2} />
          <StepHolder msg="Fill out details of the service, for more information about the fields click on the small info button." />
        </Div>
        <Div
          style={{
            height: "calc(100% - 32px)",
            overflow: "auto",
          }}
        >
          {loading && <Loader />}
          <ImageHolder src={paymentImage} style={{ height: "25vh" }} />
          <TextHolder msg="Payment" type="title" />
          <TextHolder
            msg="Important step to make the services you offer available to the public to be purchased."
            type="info"
          />
          <TextHolder
            msg="Disclaimer: Have your direct deposit information ready. This information is used only by Strip so that you can receive payments seemlessly."
            type="info"
          />
          <StepHolder msg="Access the payment setup: " flows={flows} />
          {/* <StepHolder msg="Clicking the button below."/> */}

          <center>
            <BigButton
              onClick={() => setShowWalkthrough(true)}
            >
              Get Started
            </BigButton>
          </center>
        </Div>
      </Carousel>
    </div>
  );
};
