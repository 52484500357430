import { Select } from "antd"

const Currencies = ({currency, setCurrency}) => (
    <Select defaultValue='CAD' value={currency} bordered={false} onSelect={setCurrency}>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="USD">USD</Select.Option>
    </Select>
)

export default Currencies



