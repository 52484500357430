import React from "react";
import { PlusOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { Form } from "antd";
import TextInput from "../../inputs/TextInput";
import { RegularButton, DangerGhostButton } from "../../customComponents";

function Links() {
  return (
    <Form.List name="links" >
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key} style={{marginBottom: 16, display: 'flex', 'align-items': 'center'}}>
                <div style={{display: 'flex', 'flex-direction': 'column', 'flex-grow': '1'}}>
                  <TextInput tight required={true} placeholder="Text" style={{paddingRight: 16, marginBottom: 8}} label={[index, "text"]}/>
                  <TextInput tight required={true} placeholder="Link" style={{paddingRight: 16, marginBottom: 0}} label={[index, "url"]}/>
                </div>
                <DangerGhostButton onClick={() => remove(field.name)} icon={<CloseCircleOutlined />} /> 
              </div>
            ))}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Link
                </RegularButton>
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Links;
