import Layout from '../elements/Layout';
import { LoginOutlined } from '@ant-design/icons'
import { useState } from 'react';
import {
  useNavigate
} from "react-router-dom";
import EventList from '../lists/EventList';
import { Select, Form } from 'antd';
import DateRangeInput from "../inputs/DateRangeInput";

const { Option } = Select;

export default ({ cloudUtils }) => {

  const navigate = useNavigate()
  const cities = ['Toronto, ON, Canada']
  const [selectedCities, setCities] = useState([])
  const [dateRange, setDates] = useState([])

  return (
    <Layout 
      title="View Events"
      // page="Menu"
      // pageType="Events"
      back="/"
      headerButtons={[
          <LoginOutlined onClick={() => navigate('/login')} />
        ]}
    >
      <Form
        layout='vertical'       
      >
        <h3 align="left">Select City</h3>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Filter by city"
          defaultValue={[]}
          onChange={setCities}
        >
          {cities.map((name) => <Option key={name}>{name}</Option>)}
        </Select>
        <br/><br/>
        <DateRangeInput 
          onChange={(values) => values ? setDates(values.map((mom) => mom.valueOf())) : setDates([])} 
          required={false} displayText={`Date`} label="date" 
        />
      </Form>

      <EventList cloudUtils={cloudUtils} cities={selectedCities} dates={dateRange}/>
    </Layout>
  )

};