import { useState, useEffect } from 'react'
import InfoCard from '../elements/InfoCard'
import moment from 'moment'

export default ({ eventId, cloudUtils, onClick, profileId, profile }) => {
  const [eventBook, setEvent] = useState({
    name: 'Loading...',
    description: '',
    cost: {},
    date: ' to ',
    attendees: []
  })

  useEffect(() => {
    cloudUtils.getDocument("event", eventId, (data) => {if (data) {setEvent(data)}})
  }, [])

  const STATUSES = {
    'HOST': '| Hosting',
    'SAVED': '| Saved',
    'PAID': '| Booked',
    'CANCELLED': '| Cancelled'
  }

  let { status } = profileId ? eventBook.attendees.find(({user}) => user === profileId) : false
  let time = Array.isArray(eventBook.date) ? eventBook.date.map((time) => moment(time).format('MMM Do, YYYY (h:mm a)')).join(' to ') : null

  return (
    <InfoCard 
      onClick={onClick}
      time={time}
      title={eventBook.name}
      subtitle={eventBook.city && eventBook.city.split(',')[0]}
      price={eventBook.cost.free ? 0 : eventBook.cost.value}
      status={status && STATUSES[status]}
      desc={eventBook.description}
      theme={profile && profile.theme}
      timeHeader='Event Time'
    />
  )

}

