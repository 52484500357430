import { Form, Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Label } from '../customComponents';


const inputBoxStyle = {
    borderRadius: 5,
}

const BigTextInput = ({ tight, displayText, label, required, lines, tooltip, style, initialValue=null, ...rest}) => {


    return (
        <Form.Item
            className={tight ? 'tight' : null}
            initialValue={initialValue}
            label={displayText && 
                <Label>{displayText}
                    {tooltip && 
                        <Tooltip placement='bottomLeft' title={tooltip}><sup><InfoCircleOutlined style={{fontSize: ".7rem"}}/></sup></Tooltip>
                    }
                </Label>}
            name={label}
            rules={[
                {
                    required,
                    message: ``
                }
            ]}
            style={style}
        >
            <Input.TextArea rows={lines || 4} {...rest} style={inputBoxStyle} />
        </Form.Item>
    )

};

export default BigTextInput
