import { Form, Space } from "antd"
import { SubmitButton } from "../customComponents"

export default ({text, icon, onClick, margin = false}) => { 
    if (onClick){
        return (
            <Form.Item style={{margin: margin || 32}}>
                <center>
                    <Space size='large' >
                        <SubmitButton icon={icon} onClick={onClick}>
                            {text}
                        </SubmitButton>
                    </Space>
                </center>
            </Form.Item>
        )
    }

    return (
        <Form.Item style={{margin: margin || 32}} >
            <center>
                <Space size='large' >
                    <SubmitButton icon={icon} htmlType="submit" shape="round" >
                        {text}
                    </SubmitButton>
                </Space>
            </center>
        </Form.Item>
    )
}
