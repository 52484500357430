import Info from '../elements/Info'
import React, { useState, useEffect, useRef } from 'react';
import { Form, Affix } from 'antd';
import TextInput from '../inputs/TextInput'
import Loader from '../elements/Loader';
import { useParams } from 'react-router-dom';
import { PageHeader, Div } from '../customComponents';
import OnboardingSlides from '../elements/OnboardingSlides';
import Submit from '../inputs/Submit'
import LocationInput from '../inputs/LocationInput'
import Layout from '../elements/Layout'

export default ({ cloudUtils, auth }) => {
    const { profileId } = useParams()
    const [profile, setProfile] = useState({ theme: 'default' })
    const [handle, setHandle] = useState(false)
    const [newProfileId, setNewProfileId] = useState()
    const [place, setPlace] = useState()
    const [address, setAddress] = useState()
    const [showWalkthrough, setShowWalkthrough] = useState(false)
    const inputRef = useRef(null);

    const onBackButtonEvent = (e) => {
      e.preventDefault();
      auth().signout().then(() => {
        console.log("signed out")
        window.history.go(-1)
      })
      alert("hold up")
    }

    useEffect(() => {
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
      };
    }, []);
    
    useEffect(() => {
      cloudUtils.getDocument("profile", profileId, setProfile)
    }, [profileId])

    let createUser = cloudUtils.getFunction('createUser')
    const [loading, setLoading] = useState(false)
    


    const onFinish = (values) => {
      profile.transactional = false
      setLoading(true)
      createUser({
        id: values.handle,
        location: address,
        job: values.job,
        flip: true,
        oldId: profileId,
        ...profile
      }).then(({data}) => {
        window.location.href = `/${values.handle}`
      })
    }

    const checkHandle = {      
      validator: async () => {
        return (await cloudUtils.getDocumentB("profile", handle)).exists ? Promise.reject() : Promise.resolve()
      },
      message: 'Handle is already taken!',
      required: true,
      validateTrigger: 'onSubmit'
    }

    const acceptedChars = {      
      validator: () => /^[a-zA-Z0-9-]*$/.test(handle) ? Promise.resolve() : Promise.reject(),
      message: 'Handle must only contain letters, numbers and hyphens!',
      required: true,
      validateTrigger: 'onSubmit'
    }

    return (
      <>
        {showWalkthrough ? 
          (<Layout
            title="Welcome to SoCo"
            >
              <Form
                layout='vertical'
                name="setting"
                autoComplete="off"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                >
                
                <Info messages={[
                  `Hey ${profile.name}, welcome to SoCo!`,
                  `With SoCo, you can accept bookings straight from your social media bio.`,
                  `Reduce back and forth with automatic quoting, reviews and built-in payments.`         
                ]}/>

                <TextInput  
                  onChange={(e) => setHandle(e.target.value)}
                  displayText="Handle" label="handle" required={true} validators={[checkHandle, acceptedChars]}
                  placeholder="Enter a username for your personal link"
                />

                <LocationInput inputRef={inputRef} setPlace={setPlace} setAddress={setAddress}/>

                <TextInput displayText="Profession" label="job" required={true} placeholder="Enter your job title" />

                <Submit text="Next"/>    
              </Form >
          </Layout>):(<>
            <OnboardingSlides cloudUtils={cloudUtils} profile={profile} profileId={newProfileId} setShowWalkthrough={setShowWalkthrough}/>
          </>)
        }
      </>
    )
  

};