import { Modal, Image } from 'antd';
import { useEffect, useState } from 'react';
import EventForm from '../forms/EventForm';
import {
  useNavigate,
  useParams,
} from "react-router-dom";

import { StarFilled, StarOutlined, DeleteOutlined, ExclamationCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import Layout from '../elements/Layout';
import { Divider }from '../customComponents';
import moment from 'moment'
import { DangerGhostButton, DangerButton, GhostButton, RegularButton } from '../customComponents';

const { confirm } = Modal;

export default ({ cloudUtils, user }) => {
  let [total, setTotal] = useState(0)
  let [cover, setCover] = useState('')
  let [scanning, setScanning] = useState('')
  let [scan, setScan] = useState('')
  let [hosts, setHosts] = useState([])
  let [starred, setStarred] = useState(false)
  let [joined, setJoined] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { eventId } = useParams()
  const goBack = () => navigate(-1)
  const [profile, setProfile] = useState()
  const [eventBook, setEvent] = useState({
    cost: {
      currency: 'CAD',
      value: 0
    },
    attendees: []
  })
  

  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure delete this service?',
      icon: <ExclamationCircleOutlined />,
      content: 'Deleting service: ' + eventBook.name,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // deleteItem()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  useEffect(() => {
    cloudUtils.getDocument("event", eventId, setEvent)
    if (user){
      cloudUtils.getDocumentsBy("profile", "authId", user.uid, (data) => setProfile(data[0]))
    } 
    setLoading(false)
  }, [user])


  useEffect(() => {
    if (profile){
      if (eventBook.serviceImage){
        cloudUtils.getDownloadURL(eventBook.serviceImage).then((src) => setCover(src))
      }

      eventBook.attendees.filter(({status}) => status === 'HOSTING').map(({user}) => {
        setHosts(x => [user, ...x])
        // cloudUtils.getDocument("profile", user, (data) => setHosts(x => [data.id, ...x]))
      })
      setJoined(eventBook.attendees.map(({user}) => user).includes(profile.id))
      let tempEvent = profile.events ? profile.events.find(({id}) => id == eventId) : false
      if (tempEvent){
        setStarred(tempEvent.starred)
      }
    }
  }, [profile, eventBook])

  // const deleteItem = async () => {
  //   await cloudUtils.deleteTemplate(templateId)
  //   goBack()
  // }

  const infoBox = {
    fontWeight: '600',
    padding: '4px 0',
  }
 
  const star = async () => {
    if (starred) {
      if (profile.events){
        let otherEvents = profile.events.filter(({id}) => id != eventId)
        if (eventBook.attendees.find(({user}) => user == profile.id)){
          await cloudUtils.updateDocument("profile", profile.id, {events: [{id: eventId, starred: false}, ...otherEvents]}, 'UPDATE')
        } else {
          await cloudUtils.updateDocument("profile", profile.id, {events: [...otherEvents]}, 'UPDATE')
        }
      }
      setStarred(false)
    } else {
      if (profile.events){
        let otherEvents = profile.events.filter(({id}) => id != eventId)
        await cloudUtils.updateDocument("profile", profile.id, {events: [{id: eventId, starred: true}, ...otherEvents]}, 'UPDATE')
      } else {
        await cloudUtils.updateDocument("profile", profile.id, {events: [{id: eventId, starred: true}]}, 'UPDATE')
      }
      setStarred(true)
    }
  }

  const joinEvent = async () => {
    if (eventBook.cost.free || !eventBook.cost.value){
      cloudUtils.updateDocument("event", eventId, {attendees: [{user: profile.id, status: 'JOINED'}, ...eventBook.attendees]}, 'UPDATE')
      if (profile.events){
        cloudUtils.updateDocument("profile", profile.id, {events: [{id: eventId, starred}, ...profile.events]}, 'UPDATE')
      } else {
        cloudUtils.updateDocument("profile", profile.id, {events: [{id: eventId, starred}]}, 'UPDATE')
      }   
    } else {
      let eventPayment = cloudUtils.getFunction('eventPayment')
      let data = await eventPayment({
        currency: eventBook.cost.currency, 
        name: eventBook.name, 
        amount: eventBook.cost.value, 
        buyer: profile, 
        eventId, 
        starred,
        host: eventBook.primary
      })
      window.location.href = data.url
    }
    setJoined(true)
  }

  const leaveEvent = async () => {
    await cloudUtils.updateDocument("event", eventId, {attendees: eventBook.attendees.filter(({user}) => user != profile.id)}, 'UPDATE')
    if (profile.events){
      await cloudUtils.updateDocument("profile", profile.id, {events: profile.events.filter(id => id != eventId)}, 'UPDATE')
    } 
    setJoined(false)
  }

  const cancelEvent = async () => {
    await cloudUtils.updateDocument("event", eventId, {
      cancelled: true, 
      // attendees: eventBook.attendees.map(({user}) => {
      //   return {
      //     status: 'CANCELLED',
      //     user
      //   }
      // })
    }, 'UPDATE')
    window.location.reload()
  }

  let button = <DangerGhostButton onClick={cancelEvent} />
  
  let button2 = null
  let closeScan = <DangerButton onClick={() => setScanning(false)}>Stop Scanning</DangerButton>

  if (!profile || !hosts.includes(profile.id)){
    button = <GhostButton onClick={star} icon={starred ? <StarFilled /> : <StarOutlined />} />
    if (joined){
      button2 = <div align="center" style={{padding: '32px 0'}}><DangerButton onClick={leaveEvent} icon={<DeleteOutlined/>} >Leave</DangerButton></div>
    } else {
      button2 = <div align="center" style={{padding: '32px 0'}}><RegularButton onClick={joinEvent} icon={<CalendarOutlined/>} >Attend</RegularButton></div>
    }
  }

  let time = Array.isArray(eventBook.date) ? eventBook.date.map((time) => moment(time).format('MMM Do, YYYY (h:mm a)')).join(' to ') : null

  if (eventBook.cancelled){
    return (
      <Layout
        title={eventBook.name}
        onBack={goBack}
        theme={profile.theme}
      >
        <h3>This event has been cancelled</h3>
      </Layout>
    )
  }

  if (scanning){
    return (
      <Layout
        title={eventBook.name}
        onBack={goBack}
        headerButtons={[closeScan]}
      >
        {/* <QrReader
          delay={100}
          style={{
            height: 240,
            width: 320,
          }}
          onError={console.log}
          onScan={setScan}
          />
        <p>{scan}</p>       */}
      </Layout>
    )
  }
  return (
    <Layout
      title={eventBook.name}
      onBack={goBack}
      headerButtons={profile ? [button] : null}
    >
      {user ? (
        <div align="left">
          <div align="center">
            <p style={{'white-space': 'pre-wrap'}}>{eventBook.description}</p>
            {cover ? <Image src={cover} style={{ maxWidth: '100%', maxHeight: '30vh', padding: 32 }} alt='Cover Image'/> : null}
          </div>

              
          <div style={infoBox}>Price: <span style={{fontWeight: 'lighter'}}>{(eventBook.cost.free || !eventBook.cost.value) ? 'Free' : `$${(eventBook.cost.value + total).toFixed(2)} (${eventBook.cost.currency})`}</span></div>
          <div style={infoBox}>Date: <span style={{fontWeight: 'lighter'}}>{time}</span></div>
          
        </div>
      ) : <h3>You must be signed in to attend events</h3>}

      {profile ? (hosts.includes(profile.id) ? (
        <>
          <Divider displayText="Edit event"/>
          <EventForm cloudUtils={cloudUtils} setLoading={setLoading} eventBookId={eventId} />
        </>
        ) : button2
      ) : null }
    </Layout>
  )
}





