import { Form, Upload, Select, Tooltip, Modal } from "antd";
import {
  UploadOutlined,
  DollarCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import TextInput from "../inputs/TextInput";
import BigTextInput from "../inputs/BigTextInput";
import toTitleCase from "../utils/titleCase";
import Submit from "../inputs/Submit";
import toolTipText from "../utils/toolTips";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Links from "./dynamic/Links";
import LocationInput from "../inputs/LocationInput";
import ToggleInput from "../inputs/ToggleInput";
import Info from "../elements/Info";
import TimeRangeInput from "../inputs/TimeRangeInput";
import moment from "moment";
import BusyDays from "../inputs/BusyDays";
import ImgCrop from "antd-img-crop";
import {
  RegularButton,
  DangerGhostButton,
  Label,
  Divider,
  DangerButton,
} from "../customComponents";
import NumberInput from "../inputs/NumberInput";
import { phoneValidator } from "../utils/validators";

const { confirm } = Modal;

const prefixes = {
  instagram: "instagram.com/",
  youtube: "youtube.com/",
  spotify: "open.spotify.com/artist/",
  github: "github.com/",
  facebook: "facebook.com/",
  linkedin: "linkedin.com/in/",
  tiktok: "tiktok.com/@",
};

export default ({
  auth,
  cloudUtils,
  setLoading,
  profile,
  profileId,
  setSave,
  startSpinner,
}) => {
  const [showSocials, setShowSocials] = useState([]);
  const [activeSocials, setActiveSocials] = useState(Object.keys(prefixes));
  const [existingLinks, setExistingLinks] = useState({});
  const [cardPayment, setCardPayment] = useState(false);
  const [shareEmail, setShareEmail] = useState(false);
  const [theme, setTheme] = useState(false);

  const [form] = Form.useForm();
  const [debounce, setDebounce] = useState();

  const inputRef = useRef(null);
  const [place, setPlace] = useState();
  const [address, setAddress] = useState();

  useEffect(async () => {
    // if (address){
    await cloudUtils.updateDocument(
      "profile",
      profileId,
      { location: address || "" },
      "UPDATE"
    );
    // }
  }, [address]);

  let onboard = cloudUtils.getFunction("onboard");
  let dashboard = cloudUtils.getFunction("dashboard");

  const toOnboard = () => {
    setLoading(true);
    onboard({
      email: profile.email,
      id: profileId,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  const toDashboard = () => {
    setLoading(true);
    dashboard({
      stripeId: profile.stripe,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  const onFinish = (base, raw) => {
    startSpinner(true);
    if (debounce) {
      clearTimeout(debounce);
    }
    form
      .validateFields()
      .then(() => {
        setSave(true);
      })
      .catch((e) => {
        if (e.errorFields.length) {
          setSave(false);
          startSpinner(false);
          // form.scrollToField(e.errorFields[0].name)
        } else {
          setSave(true);
        }
      });

    setDebounce(
      setTimeout(async () => {
        let { phone, closedDays, openHours, uploaded, pic, links, ...values } =
          raw || base;

        let filtered = Object.fromEntries(
          Object.entries(values).map(([k, v]) => {
            let site = k.replace("socials.", "");
            if (Object.keys(prefixes).includes(site) && v) {
              if (v.includes(prefixes[site])) {
                return [k, v.substring(v.indexOf(prefixes[site]))];
              }
              return [k, prefixes[site] + v];
            } else {
              return [k, v];
            }
          })
        );
        activeSocials.forEach((social) => {
          filtered[`socials.${social}`] = "";
        });

        if (phone && String(phone).length != 10) {
          return;
        } else {
          phone = false;
        }
        
        if (uploaded && uploaded[0].name) {
          filtered.uploaded =
            profileId +
            "/" +
            new Date().getTime() +
            "." +
            uploaded[0].name.split(".").pop();
          filtered.theme = "uploaded";
          await cloudUtils.uploadFile(filtered.uploaded, uploaded);
        }

        // if (address){
        filtered.location = address;
        // }

        if (openHours) {
          filtered.openHours = openHours.map((d) => d.format("HH:mm"));
        }

        if (closedDays) {
          filtered.closedDays = closedDays;
        }

        // filtered.bio = filtered.bio || ''
        filtered.location = filtered.location || "";

        filtered.theme = theme;
        filtered.links = (links || [])
          .map(({ text, url }) =>
            text && url
              ? { text, url: url.replace(/(https)?(http)?:?(\/\/)?/, "") }
              : false
          )
          .filter((t) => t);
        // filtered.cardPayment = cardPayment
        await cloudUtils.updateDocument(
          "profile",
          profileId,
          filtered,
          "UPDATE"
        );
        startSpinner(false);
      }, 250)
    );
  };

  function deleteAccount() {
    confirm({
      title: "Delete Profile?",
      content: `
            Are you sure you want to delete your profile? You cannot undo this action, and will lose all of your data! 
          `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cloudUtils.deleteProfile(profileId, auth().currentUser).then(() => {
            window.location = "//info.soco.market";
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useEffect(async () => {
    if (cardPayment == false || !profile.onboarding) {
      await cloudUtils.updateDocument(
        "profile",
        profileId,
        { cardPayment },
        "MERGE"
      );
    }
    await cloudUtils.updateDocument(
      "profile",
      profileId,
      { theme, shareEmail },
      "MERGE"
    );
  }, [cardPayment, theme, shareEmail]);

  useEffect(() => {
    let newProfile = { ...profile };
    let sites = Object.keys(profile.socials || {});

    const removeSocials = [];
    sites.forEach((social) => {
      if (profile.socials[social] && social !== "website") {
        removeSocials.push(social);
      }
      if (social in prefixes) {
        Object.assign(newProfile, profile, {
          ["socials." + social]: profile.socials[social].replace(
            prefixes[social],
            ""
          ),
        });
      } else {
        Object.assign(newProfile, profile, {
          ["socials." + social]: profile.socials[social],
        });
      }
    });

    setShowSocials(removeSocials);
    setActiveSocials(activeSocials.filter((x) => !removeSocials.includes(x)));

    setExistingLinks(profile.links || []);
    setCardPayment(newProfile.cardPayment);
    setShareEmail(newProfile.shareEmail);
    setTheme(newProfile.theme);
    if (newProfile.openHours) {
      newProfile.openHours = newProfile.openHours.map((d) =>
        moment(d, "HH:mm")
      );
    }

    form.setFieldsValue(newProfile);
    setAddress(newProfile.location);
    inputRef.current.setValue(newProfile.location);
    setLoading(false)
  }, [profile]);

  const picUpload = async (value) => {
    startSpinner(true);

    console.log("pic uploading");
    let picUrl =
      profileId +
      "/" +
      new Date().getTime() +
      "." +
      value.name.split(".").pop();
    await cloudUtils.uploadFileWithoutOriginFileObj(picUrl, value);
    await cloudUtils.updateDocument(
      "profile",
      profileId,
      { pic: picUrl },
      "UPDATE"
    );

    startSpinner(false);
  };

  const addSocial = (value) => {
    if (!showSocials.includes(value)) {
      setActiveSocials((prev) => prev.filter((x) => x != value));
      showSocials.push(value);
    }
  };

  const removeLink = (key) => {
    delete existingLinks[key];
    setExistingLinks({ ...existingLinks });
  };

  return (
    <Form
      layout="vertical"
      align="left"
      form={form}
      name="setting"
      autoComplete="off"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onValuesChange={onFinish}
    >
      <label className="ant-col ant-form-item-label">
        <Label>Profile Picture</Label>
      </label>
      <br />
      <ImgCrop onModalOk={(e) => picUpload(e)} rotate>
        <Upload
          customRequest={({ onSuccess }) => onSuccess("ok")}
          className="upload-list-inline"
          accept="image/*,video/*,audio/*"
        >
          <RegularButton type="dashed" icon={<UploadOutlined />}>
            Upload
          </RegularButton>
        </Upload>
      </ImgCrop>
      <br />

      <TextInput displayText="Name" label="name" required />
      <TextInput
        displayText="Profession"
        label="job"
        placeholder="Enter your job title"
        required
      />
      <NumberInput
        addonBefore="+1"
        displayText="Phone Number"
        label="phone"
        required={false}
        formatter={(value) => {
          let s = String(value);
          if (s.length > 6) {
            return `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6, 10)}`;
          }
          if (s.length > 3) {
            return `(${s.slice(0, 3)}) ${s.slice(3, 6)}`;
          }
          if (s.length > 0) {
            return `(${s.slice(0, 3)}`;
          }
        }}
        validators={[phoneValidator]}
        parser={(value) => parseInt(value.replace(/\D/g, ""))}
      />

      <ToggleInput
        displayText="Share Email"
        label="shareEmail"
        state={shareEmail}
        setState={setShareEmail}
      />

      <LocationInput
        inputRef={inputRef}
        setPlace={setPlace}
        setAddress={setAddress}
      />

      <label className="ant-col ant-form-item-label">
        <Label>Theme</Label>
      </label>
      <br />
      <Select onSelect={setTheme} style={{ width: 120 }} value={theme}>
        {["default", "cool", "coffee", "dark"].map((theme) => (
          <Select.Option value={theme}>{toTitleCase(theme)}</Select.Option>
        ))}
      </Select>
      {/* <BigTextInput displayText="Bio" label="bio" lines={3}/> */}
      <br />
      <br />
      <Divider displayText="Socials" />

      {showSocials.map((x) => (
        <>
          <DangerGhostButton
            icon={<CloseCircleOutlined size="small" />}
            onClick={() => {
              setShowSocials((prev) => prev.filter((y) => y !== x));
              setActiveSocials((prev) => [x, ...prev]);
              form.submit();
            }}
            style={{ float: "right" }}
          />
          <TextInput
            displayText={toTitleCase(x)}
            label={"socials." + x}
            required={true}
            prefix={prefixes[x]}
          />
        </>
      ))}

      <label className="ant-col ant-form-item-label">
        <Label>Add Socials</Label>
        <Tooltip title={toolTipText.links}>
          <sup>
            <InfoCircleOutlined
              style={{ fontSize: ".7rem", paddingLeft: "0.5rem" }}
            />
          </sup>
        </Tooltip>
      </label>
      <br />
      <Select onSelect={addSocial} style={{ width: 120 }}>
        {activeSocials.map((social) => (
          <Select.Option value={social}>{toTitleCase(social)}</Select.Option>
        ))}
      </Select>
      <br />
      <br />

      <Divider displayText="Links" />
      <Links existingLinks={existingLinks} removeLink={removeLink} />

      <Divider displayText="Booking" />

      <TimeRangeInput
        displayText="Available Hours"
        label="openHours"
        required={false}
      />
      <BusyDays displayText="Busy Days" />

      <Divider displayText="Payments" />

      <ToggleInput
        displayText="Accept Card Payments"
        label="cardPayment"
        state={cardPayment}
        setState={setCardPayment}
      />
      <b>
        {" "}
        Your paid services will be hidden until payment details are provided{" "}
      </b>

      {cardPayment ? (
        <div align="center" style={{ marginBottom: 24 }}>
          <Submit
            icon={<DollarCircleOutlined />}
            text={
              profile.onboarding
                ? "Create Stripe Account"
                : "Payments Dashboard"
            }
            onClick={profile.onboarding ? toOnboard : toDashboard}
          />
          <Info
            messages={[
              "Add bank transfer details to accept Google Pay, Apple Pay, VISA, Mastercard and more.",
              "There is a 2% transaction fee deducted from your payout, this will not be reflected in the customer's purchase price.",
            ]}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label> Remaining Credit:</label>
            <label style={{ fontWeight: "600", paddingBottom: 8 }}>{`$${
              profile.credit ? profile.credit.toFixed(2) : "0"
            }`}</label>
            <Info
              align="center"
              italic
              messages={[
                "Credit will automatically be used to cover transaction fees.",
              ]}
            />
          </div>
        </div>
      ) : (
        <>
          <br />
          <br />
          <BigTextInput
            displayText="Payment Instructions"
            label="paymentInstructions"
            initialValue="Contact me on chat to discuss payment details."
          />
          <Info
            messages={[
              "You can include your PayPal or other payment instructions for your customer here.",
              "SoCo does not provide support for these payments, so please use at your own discretion.",
            ]}
          />
        </>
      )}

      <Divider displayText="Account" />
      <center>
        <DangerButton onClick={deleteAccount}>Delete Account</DangerButton>
      </center>

      {/* tooltip={toolTipText.bank} */}
    </Form>
  );
};
