import { notification } from "antd";
import { useEffect, useState } from "react";
import ContactCardPage from "../elements/ContactCard";
import Onboarding from "../pages/OnboardingPage";
import qs from "query-string";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import Layout from "../elements/Layout";
// import OnboardingSlides from '../elements/OnboardingSlides';
import { Helmet } from "react-helmet";

export default ({ auth, cloudUtils, user }) => {
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/iglogo.jpg?alt=media&token=183e1ad0-2d6e-4559-a58c-a0a24e67cbfd";
  const [retries, setRetries] = useState(0);
  const { profileId } = useParams();
  let [showQr, setQr] = useState(false);
  const navigate = useNavigate();

  let location = useLocation();
  let { onboardingComplete } = qs.parse(location.search);

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: [],
    theme: "dark",
    authId: ":(",
  });

  // return <OnboardingSlides cloudUtils={cloudUtils} profile={profile} profileId={profileId} />

  let [loading, setLoading] = useState(true);
  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);

  const [pic, setPic] = useState();
  const [uploaded, setUploaded] = useState(false);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const profileSetter = (arg) => {
      if (arg) {
        setProfile(arg);
        setLoading(false);
      } else {
        setTimeout(() => {
          setRetries(retries + 1);
        }, 1000);
      }
    };

    if (retries < 5) {
      if (onboardingComplete == "true") {
        cloudUtils
          .updateDocument("profile", profileId, { onboarding: false }, "UPDATE")
          .then(() => {
            cloudUtils.getDocument("profile", profileId, profileSetter);
          });
      } else {
        cloudUtils.getDocument("profile", profileId, profileSetter);
      }
      cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
    } else {
      notification.error({
        message: "Profile not found!",
        description: "Click here to go to home page.",
        onClick: () => {
          auth()
            .signOut()
            .then(() => {
              window.location.href = "//info.soco.market";
            })
            .catch((error) => {
              console.log(error);
              window.location.href = "//info.soco.market";
            });
        },
        duration: 0,
      });
    }
  }, [retries, profileId]);

  useEffect(async () => {
    setLoading(true);
    if (profile.onboarding && admin) {
      notification.open({
        message: "Add Billing!",
        description:
          "Add your billing information to start accepting contracts and tips.",
        onClick: () => {
          navigate(`/${profileId}/profile-settings`);
        },
      });
    }

    if (profile.pic) {
      cloudUtils
        .getDownloadURL(profile.pic)
        .then((src) => setPic(src))
        .catch(() => setPic(logo))
        .then(() => setLoading(false));
    }
    if (profile.uploaded && profile.theme == "uploaded") {
      setUploaded(await cloudUtils.getDownloadURL(profile.uploaded));
    }
  }, [profile]);

  let stretchy = document.getElementsByClassName("stretchy")[0];
  if (stretchy && stretchy.getAttribute("listener") !== "true") {
    document
      .getElementsByClassName("stretchy")[0]
      .addEventListener("click", () => navigate("//info.soco.market"), false);
  }

  if (profile.transactional) {
    if (profile.handle) {
      return <Navigate push to={`/${profile.handle}`} />;
    } else {
      return (
        <Onboarding cloudUtils={cloudUtils} profile={profile} auth={auth} />
      );
    }
  } else {
    return (
      <Layout page="Menu" pageType="User" admin={admin} loading={loading} theme={profile.theme}>
        <Helmet>
          <title>{profile.name}'s SoCo</title>
          <meta
            name="description"
            content={`Book with ${profile.name} today`}
          />
        </Helmet>
        <ContactCardPage
          setQr={setQr}
          showQr={showQr}
          cloudUtils={cloudUtils}
          profile={profile}
          pic={pic}
          admin={admin}
          showButton={!profile.onboarding && templates.length}
          uploaded={uploaded}
        />
      </Layout>
    );
  }
};
