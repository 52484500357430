import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import SettingList from "../lists/SettingList";

export default ({ auth, cloudUtils, user }) => {
  const { profileId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
    authId: ":(",
  });

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId]);

  let [loading, setLoading] = useState(true)
  let [admin, setAdmin] = useState()
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId)
    if (admin >= 0){ setLoading(false) }
  }, [profile])

  return (
    <Layout page="Menu" pageType="Setting" admin={admin} loading={loading} theme={profile.theme}>
      <SettingList
        cloudUtils={cloudUtils}
        profile={{ id: profileId }}
        admin={admin}
      />
    </Layout>
  );
};
