import { Row, Col, Avatar, notification } from "antd";
import { GhostButton, Liquid, Blocks, Div } from "../customComponents";
import React, { useEffect } from "react";
import Div100vh from "react-div-100vh";
import {
  ShopOutlined,
  CloseOutlined,
  MenuOutlined,
  GithubOutlined,
  InstagramOutlined,
  CreditCardOutlined,
  GlobalOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  PhoneOutlined,
  MailOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { FaTiktok, FaSpotify, FaShareSquare } from "react-icons/fa";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceList from "../lists/ServiceList";
import logo from "../icons/logo-white.png";
import { SocialLink } from "../customComponents";
import LinkContent from "./LinkContent";
import { useTheme } from "styled-components";

export default ({ profile, pic, admin, cloudUtils }) => {
  let { profileId } = useParams();
  const theme = useTheme();

  let [ham, showHam] = useState(false);
  const [templates, setTemplates] = useState([]);

  const shareProfile = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${profile.name}'s SoCo Profile`,
          url: `soco.market/${profileId}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(`soco.market/${profileId}`);
      notification.info({
        message: "Profile copied to clipboard",
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  const flip = "flip";
  const navigate = useNavigate();

  const iconMap = {
    share: <SocialLink icon={<FaShareSquare style={{ fontSize: 28 }} />} />,
    stripe: (
      <SocialLink icon={<CreditCardOutlined style={{ fontSize: 32 }} />} />
    ),
    instagram: (
      <SocialLink icon={<InstagramOutlined style={{ fontSize: 32 }} />} />
    ),
    website: <SocialLink icon={<GlobalOutlined style={{ fontSize: 32 }} />} />,
    linkedin: (
      <SocialLink icon={<LinkedinOutlined style={{ fontSize: 32 }} />} />
    ),
    facebook: (
      <SocialLink icon={<FacebookOutlined style={{ fontSize: 32 }} />} />
    ),
    spotify: <SocialLink icon={<FaSpotify style={{ fontSize: 32 }} />} />,
    tiktok: <SocialLink icon={<FaTiktok style={{ fontSize: 32 }} />} />,
    github: <SocialLink icon={<GithubOutlined style={{ fontSize: 32 }} />} />,
    youtube: <SocialLink icon={<YoutubeOutlined style={{ fontSize: 32 }} />} />,
    twitter: <SocialLink icon={<TwitterOutlined style={{ fontSize: 32 }} />} />,
    email: <SocialLink icon={<MailOutlined style={{ fontSize: 32 }} />} />,
    phone: <SocialLink icon={<PhoneOutlined style={{ fontSize: 32 }} />} />,
    view: <ShopOutlined />,
    link: (
      <SocialLink icon={<LinkOutlined />} style={{ padding: "3px 0 9px" }} />
    ),
  };

  let spans = {
    0: 24,
    1: 24,
    2: 12,
    3: 8,
    4: 6,
    5: 4,
    6: 4,
    7: 6,
    8: 6,
  };

  let socialItems = [];

  let tipDic = {};
  if (!admin) {
    tipDic.share = "uhm";
    if (!profile.onboarding) {
      tipDic.stripe = `tip`;
    }
  }

  if (profile.socials) {
    let iconItems = { ...profile.socials, ...tipDic };
    if (profile.phone){
      iconItems.phone = profile.phone
    }
    if (profile.shareEmail){
      iconItems.email = profile.email
    }
    socialItems = Object.entries(iconItems)
      .map(([site, link]) => {
        if (site == "phone") {
          return (
            <Col
              span={spans[Object.values(iconItems).filter((x) => x).length]}
              align="center"
            >
              <GhostButton
                href={`tel:+1${link}`}
                target="_blank"
                icon={iconMap[site]}
              />
            </Col>
          );
        }
        if (site == "email") {
          return (
            <Col
              span={spans[Object.values(iconItems).filter((x) => x).length]}
              align="center"
            >
              <GhostButton
                href={`mailto:${profile.email}`}
                target="_blank"
                icon={iconMap[site]}
              />
            </Col>
          );
        }

        return link ? (
          <Col
            span={spans[Object.values(iconItems).filter((x) => x).length]}
            align="center"
          >
            <GhostButton
              href={site != "share" && `${site != "stripe" ? "/" : ""}/${link}`}
              onClick={site == "share" ? shareProfile : null}
              target="_blank"
              icon={iconMap[site]}
            />
          </Col>
        ) : null;
      })
      .filter((x) => x);
  }

  const mainCard = {
    position: "relative",
    paddingTop: admin || profile.onboarding ? 28 : 16,
    zIndex: 1,
    "justify-content": "space-evenly",
  };

  const main2 = {
    position: "relative",
    maxWidth: 480,
    width: "100%",
  };

  const main3 = {
    overflow: "scroll",
    height: "100%",
    color: theme.background,
    display: "flex",
    "flex-direction": "column",
    "justify-content": "flex-start",
  };

  const mainInfo2 = {
    "font-size": 40,
    "overflow-wrap": "break-word",
    "line-height": 48,
    "text-align": "start",
    padding: "0px 16px 16px",
    display: "flex",
    overflow: "hidden",
    "flex-direction": "row",
    "align-items": "center",
    "justify-content": "center",
  };

  const avatarIcon = {
    width: 128,
    height: 128,
  };

  const jobInfo = {
    "font-size": "24px",
    "justify-content": "center",
  };

  const locInfo = {
    "font-size": "16px",
    "justify-content": "center",
  };

  const jobIcon = {
    // width: "5rem",
    paddingRight: 12,
    "font-size": 24,
  };

  const editButtons = {
    position: "absolute",
    marginBottom: 8,
    top: 8,
    zIndex: 100,
    left: 8,
    display: "flex",
    "flex-direction": "column",
  };

  const editButton = {
    "max-width": "fit-content",
    margin: "8px 0 0 0",
    padding: "6px 12px",
    border: 0,
    "border-radius": 5,
  };

  const links = {
    position: "relative",
    maxWidth: 480,
    paddingTop: 32,
  };

  const socials = {
    padding: "16px 16px 0",
  };

  let topButton = null;
  if (admin) {
    topButton = (
      <div style={editButtons}>
        <SocialLink
          icon={ham ? <CloseOutlined /> : <MenuOutlined />}
          onClick={() => showHam((ham) => !ham)}
        />
        {ham ? (
          <>
            <Div
              style={editButton}
              onClick={() => navigate(`/${profileId}/profile-settings`)}
            >
              Settings
            </Div>
            <Div
              style={editButton}
              onClick={() => navigate(`/${profileId}/orders`)}
            >
              Orders
            </Div>
          </>
        ) : null}
      </div>
    );
  }

  const designs = {
    liquid: <Liquid />,
    block: <Blocks />,
  };
  return (
    <Div100vh >
      {topButton}

      {designs[theme.design]}

      <div style={mainCard} className={flip}>
        <div style={main2}>
          <div style={main3}>
            <div style={mainInfo2}>
              <Avatar src={pic} style={avatarIcon} />
              <div
                style={{
                  paddingLeft: "1rem",
                  width: "min-content",
                  maxWidth: "calc(100% - 128px)",
                }}
              >
                {profile.name}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                "flex-direction": "column",
                "justify-content": "flex-start",
              }}
            >
              {profile.job ? <div style={jobInfo}>{profile.job}</div> : null}

              {profile.location ? (
                <div style={locInfo}>{profile.location}</div>
              ) : null}
            </div>

            {socialItems.length ? (
              <>
                <div style={socials}>
                  <Row style={{ justifyContent: "center" }} gutter={[8, 16]}>
                    {socialItems}
                  </Row>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div style={links}>
          {(profile.links || []).map(({ text, url }) => (
            <LinkContent title={text} link={url} />
          ))}
        </div>
      </div>

      <ServiceList
        cloudUtils={cloudUtils}
        profile={{ id: profileId, ...profile }}
        templates={templates}
        admin={admin}
      />

      <div
        onClick={() => (window.location.href = "//info.soco.market")}
        style={{
          width: "-webkit-fill-available",
          display: "flex",
          justifyContent: "space-between",
          zIndex: 50,
          position: 'sticky', 
          paddingBottom: 16
          // bottom: 16,
          // right: 16,
          // left: 16
        }}
      >
        <p style={{ zIndex: 50, color: theme.background, margin: "5px 0px"}}>
          Hosted by SoCo
        </p>
        <img
          src={logo}
          style={{ maxWidth: 32, maxHeight: 32, zIndex: 50 }}
          alt="HomeIcon"
        />
      </div>
    </Div100vh>
  );
};
