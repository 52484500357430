import { useEffect, useState } from "react";
import qs from "query-string";
import Layout from "../elements/Layout";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Info from "../elements/Info";

export default ({ cloudUtils }) => {
  let { profileId } = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  let location = useLocation();
  let { id } = qs.parse(location.search);

  useEffect(async () => {
    let paymentConfirmation = cloudUtils.getFunction("paymentConfirmation");
    return await paymentConfirmation({
      profileId,
      paymentId: id,
      paymentType: "tip",
    });
  }, []);

  const [profile, useProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    theme: "dark",
    links: {},
    onboarding: false,
  });

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, useProfile);
  }, []);

  return (
    <Layout title="Success!" onBack={goBack} avatar={{ src: profile.pic }} theme={profile.theme}>
      <Info messages={["Thank you for your tip!"]} />
    </Layout>
  );
};
