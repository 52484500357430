export default {
    'Film & Photography': [
        {label: 'Videography', value: 'videography', category: 'book'},
        {label: 'Photography', value: 'photography', category: 'book'},
        {label: 'Set Design', value: 'setDesign', category: 'book'},
        {label: 'Acting', value: 'acting', category: 'book'},
        {label: 'Modelling', value: 'modelling', category: 'book'},    
    ],
    'Art & Design': [
        {label: 'Painting', value: 'painting', category: 'buy'},
        {label: 'Statues', value: 'statues', category: 'buy'},
        {label: 'Graphic Design', value: 'graphic', category: 'buy'},
        {label: 'Website Design', value: 'website', category: 'buy'},
        {label: 'Animations', value: 'animations', category: 'buy'},
    ],
    'Lifestyle': [
        {label: 'Life Coaching', value: 'lifeCoaching', category: 'book'},
        {label: 'Personal Training', value: 'personalTraining', category: 'book'},
        {label: 'Barbershop', value: 'barbershop', category: 'book'},
    ],
    'Music': [
        {label: 'Mixing', value: 'mixing', category: 'buy'},
        {label: 'Mastering', value: 'mastering', category: 'buy'},
        {label: 'Beat Production', value: 'beatProduction', category: 'buy'},
        {label: 'Studio Time', value: 'studio', category: 'book'}
    ]
}