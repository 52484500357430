import { Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Label, RegularButton } from '../customComponents';

const UploadComponent = ({ displayText, label, required, fieldSize }) => {
    return (
        <Form.Item
            label={<Label>{displayText}</Label>}
            name={label}
            rules={[
                {
                    message: `Please input your ${label}`,
                },
            ]}
        >
            <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture"
                className="upload-list-inline"
                accept="image/*,video/*,audio/*"
            >
                <RegularButton icon={<UploadOutlined />}>Upload</RegularButton>
            </Upload>
        </Form.Item>
    )
};

export default UploadComponent