import { Row } from "antd";
import React, { useEffect, useState } from "react";
import OrderCard from "../cards/OrderCard";
import { useNavigate, useParams } from "react-router-dom";
import Info from "../elements/Info";
import Layout from "../elements/Layout";

export default ({ cloudUtils }) => {
  const [loading, setLoading] = useState(true);
  const { profileId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });
  let [uploaded, setUploaded] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const goBack = () => navigate(`/${profileId}`);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  useEffect(() => {
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
  }, [profile]);

  useEffect(() => {
    setLoading(true);
    templates.forEach((template) => {
      cloudUtils.getDocumentsBy("contract", "template", template.id, (data) =>
        setContracts((prevArray) => [
          ...prevArray,
          { template, contract: data },
        ])
      );
    });

    setLoading(false);
  }, [templates]);

  useEffect(() => {
    let orders = [];
    setLoading(true);

    Object.values(contracts)
      .filter(({ contract }) => contract)
      .forEach(({ template, contract }) => {
        contract.forEach((con) => {
          if (!con.show && con.status !== "UNVERIFIED") {
            orders.push(
              <OrderCard
                title={template.name}
                customer={con.customer.name}
                email={con.customer.email}
                time={con.time}
                price={con.price}
                status={con.status}
                onClick={() => navigate(`/${profileId}/offer/${con.id}`)}
                key={"contract" + con.id}
                profile={profile}
                uploaded={uploaded}
              />
            );
          }
        });
      });
    setOrders(orders);
    setLoading(false);
  }, [uploaded, contracts]);

  return (
    <Layout title="Order History" onBack={goBack} theme={profile.theme}>
      <Row gutter={[0, 8]}>
        {orders.length ? (
          orders
        ) : (
          <Info
            messages={[
              "You don't have any orders yet! To start accepting orders, make sure you:",
              "  1. Create at least one service",
              "  2. Register Stripe in your settings page",
              "  3. Promote your SoCo link on your social media",
            ]}
          ></Info>
        )}
      </Row>
    </Layout>
  );
};
