import {Form, Input, Col, Select, Upload, message} from "antd";
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import Survey from "./dynamic/Survey";
import Quote from "./dynamic/Quote";
import Bundle from "./dynamic/Bundle";
import TextInput from "../inputs/TextInput";
import ToggleInput from "../inputs/ToggleInput";
import BigTextInput from "../inputs/BigTextInput";
import { useState, useEffect } from "react";
import { Divider, Text } from "../customComponents";
import {
  bundleLength,
  timePositive,
  round15
} from "../utils/validators";
import Submit from "../inputs/Submit";
import toolTips from "../utils/toolTips";
import NumberInput from '../inputs/NumberInput'
import Options from '../forms/TagSelect'

import { useNavigate, useParams } from "react-router-dom";
import { RegularButton, Label } from "../customComponents";

export default ({ cloudUtils, templateId, form, old=false }) => {
  let { profileId } = useParams();
  const navigate = useNavigate();
  // const [form] = Form.useForm();
  const [serviceText, setServiceName] = useState();
  const [priceValue, setPriceValue] = useState();
  const [priceRange, setPriceRange] = useState(false);
  const [currency, setCurrency] = useState("CAD");
  const [timeValue, setTimeValue] = useState(1);
  const [timeRange, setTimeRange] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const [free, setFree] = useState(false);
  const [unit, setUnit] = useState("Days");
  const [template, setTemplate] = useState(false);
  let [fileList, setFileList] = useState(false)
  const [selected, setSelected] = useState([])

  const fileUpload = async ({ onError, onSuccess, file }) => {
    if (file) { 
      let filePath = templateId + '/' + file.name
      try {
        const data = await cloudUtils.uploadFileWithoutOriginFileObj(filePath, file)
        onSuccess(null, data)
      } catch (e) {
        onError(e)
      }
    }
  }

  const fileDelete = async (info) => {
    let filePath = templateId + '/' + info.name
    const data = await cloudUtils.deleteFile(filePath).then(() => {
      message.success(`${info.name} file deleted successfully`);
    })
  }

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList]
    
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList)

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList(info.fileList)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    } else if(info.file.status == 'removed') {
      fileDelete(info.file)
    }
  };


  useEffect(() => {
    cloudUtils.getDocument("template", templateId, setTemplate);
    if (old) {
      cloudUtils.getUploadedFiles(`${templateId}/`, setFileList)
    }
  }, []);

  useEffect(() => {
    if (template) {
      let newTemplate = { ...template };
      setServiceName(newTemplate.name);
      setSelected(newTemplate.tags || [])

      newTemplate["priceMin"] = newTemplate.price.min;
      newTemplate["priceMax"] = newTemplate.price.max;
      newTemplate["priceValue"] = newTemplate.price.value;
      newTemplate["priceRange"] = newTemplate.price.isRange;
      newTemplate["free"] = newTemplate.price.free;
      setPriceValue(newTemplate.price.value);
      setPriceRange(newTemplate.price.isRange);
      setCurrency(newTemplate.price.currency);
      setFree(newTemplate.free)
      if (newTemplate.time){
        newTemplate["timeValue"] = newTemplate.time.value;
        setTimeValue(newTemplate.time.value);
        setUnit(newTemplate.time.unit);
      }


      setPurchase(newTemplate.purchase);

      newTemplate["survey"] = newTemplate.survey 
        ? Object.entries(newTemplate["survey"]).map(([id, vals]) => {return {id, ...vals}})
        : []

      newTemplate["quote"] = newTemplate.quote 
        ? Object.entries(newTemplate["quote"]).map(([id, vals]) => {return {id, ...vals}})
        : []
      
      newTemplate["bundle"] = newTemplate.bundle 
        ? Object.entries(newTemplate["bundle"]).map(([id, vals]) => {return {id, ...vals}}).sort((a, b) => {
          if (a.base && !b.base){
            return -1
          } else if (!a.base && b.base) {
            return 1
          } else {
            return 0
          }
        })
        : []

      form.setFieldsValue(newTemplate);
    }
  }, [template]);

  let TemplatePage = async (raw) => {
    // setLoading(true)
    let {
      file,
      priceValue,
      priceMin,
      priceMax,
      timeValue,
      timeMin,
      timeMax,
      ...values
    } = raw;

    if (values.survey) {
      let surveys = {};
      values.survey.forEach((survey) => {
        let {id, question} = survey;
        surveys[id || uuidv4()] = {question, type: 'text'};
      });
      values.survey = { ...surveys };
    }

    if (values.quote) {
      let quotes = {};
      values.quote.forEach((quote) => {
        let {id, feature, price, count} = quote;
        quotes[id || uuidv4()] = {feature, price, count};
      });
      values.quote = { ...quotes };
    }

    if (values.bundle) {
      let bundles = {};
      values.bundle.forEach((bundle) => {
        let {id, title, price, description = '', base = false} = bundle ;
        bundles[id || uuidv4()] = { title, price, description, base }
      });
      values.bundle = { ...bundles };
    }

    if (priceRange) {
      values.price = {
        currency,
        isRange: priceRange,
        free: free,
        min: parseFloat(priceMin),
        max: parseFloat(priceMax),
      };
    } else if (!free) {
      if (!Object.keys(values.bundle).length){
        alert('No packages have been created!')
        return
      }
      values.price = {
        currency,
        isRange: priceRange,
        free: free,
        value: parseFloat(0),
      };
    } else {
      values.price = {
        currency,
        isRange: priceRange,
        free: free,
        value: parseFloat(priceValue),
      };
    }

    if (purchase){
      if (timeRange) {
        values.time = {
          unit,
          isRange: timeRange,
          min: parseInt(timeMin),
          max: parseInt(timeMax),
        };
      } else {
        values.time = {
          unit,
          isRange: timeRange,
          value: parseInt(timeValue),
        };
      }
    }

    values.profile = profileId;

    if (file) {
      values.serviceImage =
        template +
        "/" +
        new Date().getTime() +
        "." +
        file[0].name.split(".").pop();
      await cloudUtils.uploadFile(values.serviceImage, file);
    }

    let filtered = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );

    filtered.purchase = purchase
    filtered.tags = selected

    await cloudUtils.updateDocument(
      "template",
      templateId,
      filtered,
      old ? "UPDATE" : "MERGE"
    );
    navigate("/" + profileId);
  };

  const selectTime = (
    <Select
      defaultValue="Days"
      value={unit}
      bordered={false}
      onSelect={setUnit}
    >
      <Select.Option value="Days">Days</Select.Option>
      <Select.Option value="Weeks">Weeks</Select.Option>
      <Select.Option value="Months">Months</Select.Option>
    </Select>
  );

  const selectCurrency = (
    <Select
      defaultValue="CAD"
      value={currency}
      bordered={false}
      onSelect={setCurrency}
    >
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );

  const selectCurrency2 = (
    <Select
      defaultValue="CAD"
      value={currency}
      onSelect={setCurrency}
    >
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );

  const charLimitValidator = (displayText, textValue, limit) => {
    return {
      validator: () => {
        if (textValue && textValue.length <= limit) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: `${displayText} must be between 1 and ${limit} characters`,
      validateTrigger: "onSubmit",
    };
  };

  const priceType = {
    "Free": <center>Free services have payment integrations disabled</center>,
    "Package": (
      <>
        <h3 style={{marginBottom: 24}}>
          Currency: <span style={{marginLeft: 16}}> {selectCurrency2} </span>
        </h3>

        <Bundle
          validators={[bundleLength]}
        />
      </>
    )
  };

  return (
    <Form
      onFinish={TemplatePage}
      onFinishFailed={() => alert('Please verify all fields have been filled correctly')}
      form={form}
      layout="vertical"
      requiredMark={false}
      align="left"
    >
      <TextInput
        required
        displayText="Service Name"
        label="name"
        validators={[charLimitValidator("Service Name", serviceText, 18)]}
        onChange={(e) => {
          setServiceName(e.target.value);
        }}
      />

      <center style={{margin: '0 32px 16px'}}>
        <Upload 
          accept="image/*,video/*,audio/*"
          name="file" 
          listType="picture"
          customRequest={fileUpload} 
          onChange={handleUploadChange} 
          multiple="true" 
          fileList={fileList ? fileList:null}>
            <RegularButton icon={<UploadOutlined />}>
              Add Cover Art
            </RegularButton>
        </Upload>
      </center>

      <BigTextInput
        required
        displayText="Details "
        label="longDesc"
        tooltip={toolTips.details}
      />

      <label className='ant-col ant-form-item-label'>
        <Label>Search Tags</Label>
      </label>
      <Options selected={selected} setSelected={setSelected}/>
        <br/>
        <br/>
        <br/>
      <ToggleInput checked="Purchase" unChecked="Booking" displayText="Booking or Purchase" label="purchase" state={purchase} setState={setPurchase} />

      {purchase ? (
        <Col>
          <TextInput
            required={true}
            validators={[timePositive(false, 0, 0, timeValue)]}
            label="timeValue"
            tooltip={toolTips.deliveryTime}
            displayText="Delivery Estimate "
            suffix={selectTime}
            onChange={(e) => setTimeValue(e.target.value)}
          />
        </Col>
      ) : (
      <Col>
        <NumberInput
          displayText="Appointment Duration (Minutes)"
          label="duration"
          step={15}
          validators={[round15()]}
          min={15}
        />
      </Col>
      )}

      <br/>
      <Divider displayText="Pricing" />

      <ToggleInput displayText="Free Service?" label="free" state={free} setState={setFree} />

      <Form.Item rules={[{ required: true }]}>
        <Input.Group>{priceType[free ? 'Free' : 'Package']}</Input.Group>
      </Form.Item>


      {free ? null :  (<>
        <Divider displayText="Addons" />
        <Quote />
        <Text style={{fontStyle: 'italic'}}>Addons are only applicable to your base package</Text>
        <br/>
      </>)}
     
      <Divider displayText="Additional Questions" />
      <Survey />
      <Submit
        style={{position: old ? 'sticky' : 'relative' }}
        text={old ? "Update" : "Create"}
        icon={<PlusCircleOutlined />}
      />
    </Form>
  );
};
