import { Row, Form } from "antd";
import React, { useEffect, useState } from "react";
import Info from "../elements/Info";
import TextInput from "../inputs/TextInput";
import {
  emailOrderValidator,
  emailValidator,
  orderIdValidator,
} from "../utils/validators";
import OrderCard from "../cards/OrderCard";
import Submit from "../inputs/Submit";
import Layout from "../elements/Layout";
import { useNavigate } from "react-router-dom";
import { LoginOutlined } from "@ant-design/icons";

export default ({ cloudUtils, admin = false }) => {
  const [email, setEmail] = useState();
  const [contracts, setContract] = useState(false);
  const [templates, setTemplate] = useState([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    cloudUtils.getAllDocuments("template", setTemplate);
  }, []);

  const onFinish = async (raw) => {
    let { email, pin } = raw;
    cloudUtils.getDocument("customer", email, (d) => {
      if (d) {
        if (d.passcode == pin) {
          cloudUtils.getDocumentsBy(
            "contract",
            "customer.email",
            email,
            setContract
          );
        } else {
          alert("No orders found");
        }
      } else {
        alert("No user exists with this email");
      }
    });
  };

  useEffect(() => {
    if (contracts) {
      contracts.forEach((data) => {
        cloudUtils.getDocument("template", data.template, (template) => {
          if (data.status != "UNVERIFIED" && data.customer != undefined && template) {
            if (data.customer.email === email) {
              setOrders((prevData) => [
                ...prevData,
                <OrderCard
                  tracking
                  title={template.name}
                  customer={template.profile}
                  email={data.customer.email}
                  time={data.time}
                  price={data.price}
                  status={data.status}
                  onClick={() =>
                    window.open(`/${data.profile}/offer/${data.id}`)
                  }
                  key={"contract" + data.id}
                  profile={{theme: 'default'}}
                />,
              ]);
            }
          }
        });
      });
    }
  }, [contracts]);

  const formBox = {
    padding: "10px 10px 1px 10px",
  };

  return (
    <Layout
      title="Track your Order"
      back="/"
      headerButtons={[<LoginOutlined onClick={() => navigate("/login")} />]}
    >
      {!contracts && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div align="center">
            <Info
              align="center"
              messages={[
                "Search for your orders by entering your email and order number.",
              ]}
            />
            <div>
              <Form
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <TextInput
                  displayText="Email"
                  label="email"
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                  validators={[emailValidator]}
                />
                <TextInput displayText="Pin" label="pin" required={true} />
                <Submit text="Submit" />
              </Form>
            </div>
            {/* <Info align="center" messages={['To track your orders as a seller, please login above']}/> */}
          </div>
        </div>
      )}

      {contracts && (
        <Row gutter={[0, 8]}>
          {orders}
        </Row>
      )}
    </Layout>
  );
};
