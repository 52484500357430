import {
  PageHeader,
  Button,
  Divider,
  Radio,
  InputNumber,
  Tag,
  Typography,
} from "antd";
import styled from "styled-components";

const Div = styled.div`
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.background};
`;

const TransDiv = styled.div`
  color: ${props => props.theme.primary}
`;


const InvertedDiv = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.background};
`;

const AccentDiv = styled.div`
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.background};
`;

const ReceiverText = styled.p`
  padding: 8px;
  color: ${props => props.theme.primary};
`;

const ReceiverMessage = styled(({ children, className }) => (
  <div className={className}>
    <ReceiverText>{children}</ReceiverText>
  </div>
))`
  width: fit-content;
  background: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.primary};
  margin-bottom: 8px;
  border-radius: 5px;
  margin-right: auto;
  margin-left: 0;
`;

const SenderText = styled.p`
  padding: 8px;
  color: ${props => props.theme.background};
`;


// background-image: linear-gradient(
//   20deg,
//   ${props => props.theme.primary} 20%,
//   ${props => props.theme.accent} 70%,
//   ${props => props.theme.secondary} 100%
// );
// background-size: 100% 100%;
// background-attachment: fixed;

const SenderMessage = styled(({ children, className }) => (
  <div className={className}>
    <SenderText>{children}</SenderText>
  </div>
))`
  width: fit-content;
  background: ${props => props.theme.primary};
  margin-bottom: 8px;
  border-radius: 5px;
  margin-right: 0;
  margin-left: auto;
`;

const MachineMessage = styled(({ children, className }) => (
  <div className={className}>
    <ReceiverText>{children}</ReceiverText>
  </div>
))`
  border-radius: 5px;
  margin-right: auto;
  margin-left: auto;
`;

const Text = styled.p`
  color: ${props => props.theme.primary};
`;

const OptionTag = styled(({ selected, ...props }) => <Tag {...props} />)`
  color: ${(props) => (props.selected ? props.theme.background: props.theme.primary)};
  background-color: ${(props) => (props.selected ? props.theme.primary : props.theme.background)};
  width: 50px;
  border-radius: 5px;
  margin: 6px;
  padding: 6px;
`;

const CustomRadio = styled(Radio)`
  color: ${props => props.theme.primary};
`;


const Blocks = styled((props) => <div {...props} />)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: ${props => props.theme.primary};
  transition: 1s;

  &:before {
    content: "";
    position: absolute;
    left: 75vw;
    width: 70vh;
    height: 70vh;
    top: 110vh;
    background: ${props => props.theme.accent};
    animation: animate reverse 60s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 25vw;
    width: 90vh;
    height: 90vh;
    top: 140vh;
    background: ${props => props.theme.secondary};
    animation: animate 75s linear infinite;
  }
`;

const Liquid = styled((props) => <div {...props} />)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(${props => props.theme.primary}, ${props => props.theme.accent} 55%, ${props => props.theme.secondary} 100%);
  transition: 1s;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    filter: blur(40px);
    transform: translate(-50%, -50%);
    width: 1500px;
    height: 2000px;
    top: 15%;
    border-radius: 50%;
    background: ${props => props.theme.primary};
    animation: animate 30s linear infinite;
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    filter: blur(40px);
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 750px;
    top: 40%;
    border-radius: 50%;
    background: ${props => props.theme.accent};
    animation: animate 10s linear infinite;
  }
`;

const BackgroundProvider = styled.div`
  & > * {
    background: linear-gradient(130deg, ${props => props.theme.primary} 20%, ${props => props.theme.accent} 55%, ${props => props.theme.secondary} 100%);
  }
`;

const CustomInputNumber = styled(InputNumber)`
  color: ${props => props.theme.primary};
  border-radius: 5;
  width: 40;
`;

const Label = styled.label`
  color: ${props => props.theme.primary};
`;

const SubHeader = styled.h3`
  fontsize: 20;
  color: ${props => props.theme.primary};
  font-weight: bold;
`;

const BigHeader = styled.h1`
  color: ${props => props.theme.primary};
  font-size: 24px;
  padding-right: 12px;
  margin: 0;
  font-weight: 400;
`;

const LightText = styled.span`
  font-weight: 400;
`;

const SocialLink = styled((props) => (
  <Button type="text" className="link" {...props} />
))`
  color: ${props => props.theme.background}!important;
`;

const PrimaryIcon = styled((props) => (
  <Button type="text" className="link" {...props} />
))`
  color: ${props => props.theme.primary};
`;


const SubDivider = styled(Divider)`
  border-top-color: ${props => props.theme.primary} !important;
  color: ${props => props.theme.primary} !important;
`;

const CustomDivider = ({ displayText }) =>
  displayText ? (
    <SubDivider orientation="left">{displayText}</SubDivider>
  ) : (
    <SubDivider />
  );

const CustomParagraph = styled(Typography.Paragraph)`
  color: ${props => props.theme.primary};
`;

const borderImages = {
  liquid: `linear-gradient(
    130deg,
    ${props => props.theme.primary} 20%,
    ${props => props.theme.accent} 55%,
    ${props => props.theme.secondary} 100%
  );`,
  block: `${props => props.theme.primary}`
}



const CustomPageHeader = styled(PageHeader)`
  background: ${props => props.theme.background};
  border-image: ${props => borderImages[props.theme.design]};
  border-image-slice: 1;
  border-bottom: 1px solid;
  z-index: 3;
  color: ${props => props.theme.primary};
  position: fixed;
  width: 100%;
`;

const Page = styled.div`
  background: ${props => props.theme.background}
  color: ${props => props.theme.primary}
`;

const RegularButton = styled((props) => <Button shape="round" {...props} />)``;

const AccentButton = styled((props) => <Button shape="round" {...props} />)`
  color: ${props => props.theme.background};
  background-color: ${props => props.theme.secondary};
`;

const DangerButton = styled((props) => <Button shape="round" {...props} />)`
  color: ${props => props.theme.background};
  background-color: ${props => props.theme.danger};
`;

const DangerGhostButton = styled((props) => (
  <Button shape="round" type="text" {...props} />
))`
  color: ${props => props.theme.danger};
  padding: 0;
`;

const BigButton = styled((props) => (
  <Button shape="round" size="large" {...props} />
))`
  margin: 16px;
  padding: 8px 16px;
`;

const GhostButton = styled((props) => <Button ghost type="text" {...props} />)`
  padding: 0;
`;

const SubmitButton = styled((props) => <Button shape="round" {...props} />)`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.background};
`;

export {
  Div,
  InvertedDiv,
  TransDiv,
  AccentDiv,
  OptionTag,
  PrimaryIcon,
  RegularButton,
  BigButton,
  GhostButton,
  DangerButton,
  AccentButton,
  DangerGhostButton,
  SubmitButton,
  CustomPageHeader as PageHeader,
  Page,
  CustomDivider as Divider,
  CustomParagraph as Paragraph,
  Label,
  SubHeader,
  BigHeader,
  LightText,
  SocialLink,
  CustomRadio as Radio,
  CustomInputNumber as InputNumber,
  Text,
  ReceiverMessage,
  SenderMessage,
  MachineMessage,
  BackgroundProvider,
  Liquid,
  Blocks,
};
