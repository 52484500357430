import { Col } from 'antd';
import { SubHeader, LightText, BackgroundProvider, InvertedDiv, AccentDiv } from '../customComponents';

export default ({ title, time, subtitle, desc, price = 0, status, onClick, timeHeader='Appointment' }) => {  
  let timeSplit = false
  if (time){
    timeSplit = typeof time == 'string' ? time.split(' to ') : [time]
  } 

  return (
      <Col className="gutter-row card-back" span={24} onClick={onClick} >
        <div className="info-card-inner" align="left">
          <SubHeader>{title} <LightText>| {subtitle}</LightText></SubHeader>
          <p>{desc}</p> <br/>
          { timeSplit && timeSplit.length === 2 
            && (
            <>
              <p>{timeHeader}:</p> 
              <p style={{paddingLeft: 16}}>From: <span style={{fontWeight: 'bolder'}}>{timeSplit[0]}</span></p> 
              <p style={{paddingLeft: 16, marginBottom: 8}}>To: <span style={{fontWeight: 'bolder'}}>{timeSplit[1]}</span></p> 
            </>)
          }
          {status && (<AccentDiv className='floating-status'>{status}</AccentDiv>)}
          <InvertedDiv className='floating-action'>{price > 0 ? '$' + price : 'Free'}</InvertedDiv>
        </div>

      </Col>

  )
}