import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Div } from "../customComponents";

export default ({ title, link }) => {
  let [showLinkContent, setShowLinkContent] = useState(false);
  const navigate = useNavigate();

  //Link type check and parse
  function LinkParse() {
    if (link.match(/youtube.+watch/)) {
      let id = link.slice(link.lastIndexOf("=") + 1);
      return <YoutubeContent id={id} />;
    } else if (link.match(/youtu.be/)) {
      let id = link.slice(link.lastIndexOf("/") + 1);
      return <YoutubeContent id={id} />;
    }

    if (link.match(/spotify.+track/)) {
      let id = link.slice(link.lastIndexOf("/") + 1, link.lastIndexOf("?"));
      return <SpotifyContent id={id} />;
    } else if (link.match(/music.apple.+album/)) {
      let id = link.slice(link.indexOf("/", link.lastIndexOf(/album/) + 1) + 1);
      return <AppleMusicContent id={id} />;
    }

    return <NormalContent />;
  }
  //Youtube IFrame
  function YoutubeContent(props) {
    return (
      <>
        <EmbededContent />

        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              width="100%"
              style={{ "aspect-ratio": "16/9" }}
              src={`https://www.youtube.com/embed/${props.id}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //Spotify IFrame
  function SpotifyContent(props) {
    return (
      <>
        <EmbededContent />

        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              src={`https://open.spotify.com/embed/track/${props.id}?utm_source=generator`}
              width="100%"
              height="152"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //SoundCloud IFrame
  function SoundCloudContent(props) {
    return (
      <>
        <iframe
          width="100%"
          height="166"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1371550585&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true"
        ></iframe>
        <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
          <a
            href="https://soundcloud.com/hykeemcarter"
            title="Baby Keem"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
          >
            Baby Keem
          </a>{" "}
          ·{" "}
          <a
            href="https://soundcloud.com/hykeemcarter/bank-account-feat-lil-uzi-vert"
            title="bank account (feat. Lil Uzi Vert)"
            target="_blank"
            style="color: #cccccc; text-decoration: none;"
          >
            bank account (feat. Lil Uzi Vert)
          </a>
        </div>
      </>
    );
  }
  //Apple Music IFrame
  function AppleMusicContent(props) {
    return (
      <>
        <EmbededContent />
        {showLinkContent ? (
          <div style={{ paddingTop: 12 }}>
            <iframe
              allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
              frameborder="0"
              height="175"
              style={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              src={`https://embed.music.apple.com/us/album/${props.id}`}
            ></iframe>
          </div>
        ) : null}
      </>
    );
  }
  //Normal Tab link
  function NormalContent() {
    return (
      <Row>
        <Col span={20}>
          <span>{title}</span>
        </Col>
        <Col
          span={4}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(`//${link}`, "_blank");
          }}
        >
          <GlobalOutlined />
        </Col>
      </Row>
    );
  }

  //Enhanced Content
  function EmbededContent() {
    return (
      <Row>
        <Col span={20}>
          <span>{title}</span>
        </Col>
        <Col span={4}>
          {showLinkContent ? <UpCircleOutlined /> : <DownCircleOutlined />}
        </Col>
      </Row>
    );
  }

  return (
    <Div
      style={{
        marginBottom: 16,
        borderRadius: 5,
        "box-shadow": "0px 4px 4px rgba(0,0,0,0.25)",
        "font-size": 16,
        padding: 12,
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        setShowLinkContent(!showLinkContent);
      }}
    >
      <LinkParse />
    </Div>
  );
};
