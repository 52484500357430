import { Rate, Affix, Modal, Tabs, Form, InputNumber } from "antd";
import Carousel from "re-carousel";
import { DangerButton, PageHeader } from "../customComponents";
import IndicatorDots from "../elements/IndictorDots";
import { useEffect, useState } from "react";
import MultimediaSupport from "../elements/MultiMedia";
import TemplateForm from "../forms/TemplateForm";
import Loader from "../elements/Loader";
import TextInput from "../inputs/TextInput";
import Appointment from "../inputs/Appointment";
import UploadInput from "../inputs/UploadInput";
import { emailValidator } from "../utils/validators";
import Info from "../elements/Info";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import CheckboxInput from "../inputs/CheckboxInput";
import RadioInput from "../inputs/RadioInput";
import {
  CalendarOutlined,
  DeleteOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Submit from "../inputs/Submit";
import { Divider } from "../customComponents";
import {
  AccentButton,
  GhostButton,
  DangerGhostButton,
} from "../customComponents";
import Layout from "../elements/Layout";
const { confirm } = Modal;

const { TabPane } = Tabs;

const CarouselItem = ({ template, contract, cloudUtils, admin, goBack }) => {
  let [media, setMedia] = useState("");
  let [mediaName, setMediaName] = useState("");

  const deleteItem = async () => {
    await cloudUtils.deleteContract(contract.id, template);
    //TODO: better component refresh needed
    window.location.reload();
  };

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure delete this review?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useEffect(() => {
    cloudUtils.listUrls(template + "/" + contract.id + "/final").then((res) => {
      if (res.items.length) {
        const item = res.items.pop();
        return item.getDownloadURL().then((url) => {
          setMediaName(item.name);
          setMedia(url);
        });
      }
    });
  }, [contract.id, template]);

  let { desc, customer } = contract;

  return (
    <div align="center" style={{ overflow: "visible", height: "fit-content" }}>
      {customer && customer.rating && (
        <Rate allowHalf disabled defaultValue={customer.rating} />
      )}
      {customer && customer.comment && <p>{customer.comment}</p>}
      {customer && customer.name && (customer.comment || customer.rating) && (
        <p style={{ fontStyle: "italic" }}>{"   - " + customer.name}</p>
      )}

      {media && (
        <>
          <MultimediaSupport media={media} name={mediaName} />
          <p align="center">{desc}</p>
        </>
      )}

      {admin && (
        <DangerButton onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
          Remove Project
        </DangerButton>
      )}
    </div>
  );
};

export default ({ cloudUtils, user }) => {
  let [total, setTotal] = useState(0);
  let [counts, setCounts] = useState({});
  let [base, setBase] = useState(0);
  let [initial, setInitial] = useState(false);
  let [invalid, setInvalid] = useState(false);
  let [day, setDay] = useState(moment("9:00", "HH:mm"));

  let finalPrice = initial
    ? base + total + Object.values(counts).reduce((a, b) => a + b, 0)
    : base;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { profileId, templateId } = useParams();
  const goBack = () => navigate(-1);
  const [contracts, setContracts] = useState([]);
  const [template, setTemplate] = useState({
    price: {
      min: 1,
      max: 2,
      value: 0,
      currency: "CAD",
    },
    time: {
      min: 1,
      max: 2,
      value: 1,
      unit: "Days",
    },
  });
  const [profile, setProfile] = useState({});
  const [uploaded, setUploaded] = useState(false);
  const [customerPreview, setCustomerPreview] = useState(false);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    cloudUtils.getDocumentsBy("contract", "template", templateId, setContracts);
    cloudUtils.getDocument("template", templateId, setTemplate);
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId, templateId]);

  let [admin, setAdmin] = useState();
  useEffect(() => {
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);

  const deleteItem = async () => {
    await cloudUtils.deleteTemplate(templateId);
    goBack();
  };

  let adminButtons = admin
    ? [
        <GhostButton icon={<SaveOutlined />} onClick={form2.submit} />,
        <DangerGhostButton
          icon={<DeleteOutlined />}
          onClick={showDeleteConfirm}
        />,
      ]
    : [<GhostButton icon={<CalendarOutlined />} onClick={form.submit} />];

  // let previewButton = admin
  //   ? <Button onClick={() => setCustomerPreview(!customerPreview)} shape='round'>Customer Preview:&nbsp;{customerPreview? <span style={{color: 'green'}}>ON</span>:<span style={{color: accentColor}}>OFF</span>}</Button>
  //   : null

  let previewButton = null;

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure delete this service?",
      icon: <ExclamationCircleOutlined />,
      content: "Deleting service: " + template.name,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const submitSurvey = async (values, total, cloudUtils, template, profile) => {
    if (invalid){
      alert('Please select a valid date!');
      return
    }
    setLoading(true);
    const t2 = moment(day).add(template.duration, "minutes");
    let { name, email, bundle, quote, time, counts } = values;
    let submitOffer = cloudUtils.getFunction("submitOffer");
    //Parse code for surveys
    let survey = {};
    for (const [key, value] of Object.entries(values)) {
      let splitKeys = key.split(":");
      if (splitKeys.length > 1) {
        survey[splitKeys[1]] = value;
      }
    }

    const timeVal = template.purchase
      ? moment()
          .add(template.time.value, template.time.unit.toLowerCase())
          .format("MMM Do, YYYY (h:mm a)")
      : day.format("MMM Do, YYYY (h:mm a)") +
        " to " +
        t2.format("MMM Do, YYYY (h:mm a)");

    submitOffer({
      price: total ? total : 0,
      time: timeVal,
      busy: template.purchase ? false : [day.valueOf(), t2.valueOf()],
      template: { name: template.name, id: template.id },
      customer: { name, email },
      worker: profile,
      quote: quote ? quote : [],
      bundle: bundle ? bundle : {},
      counts: counts ? counts : {},
      survey,
      profile: profileId,
    }).then(({ data }) => {
      alert(`You're passcode is ${data.passcode}, you can use this find your orders later! You can always find this code in your email as well.`)
      window.location.href = data.link;
    });
  };

  const infoBox = {
    fontWeight: "600",
    padding: "4px 0",
  };

  const reviewBox = {
    padding: "32px 8px",
    flexGrow: 1,
    height: "500px",
  };

  const inputBoxStyle = {
    borderRadius: 5,
    width: 40,
  };

  let portfolio = contracts
    .filter(({ status }) => status == "UNVERIFIED")
    .map((contract) => (
      <CarouselItem
        cloudUtils={cloudUtils}
        contract={contract}
        key={templateId}
        template={templateId}
        admin={admin}
        goBack={goBack}
      />
    ));

  let displayedReviews = contracts
    .filter(
      ({ status, customer }) =>
        ["ACCEPTED", "COMPLETE"].includes(status) &&
        customer &&
        (customer.rating || customer.comment)
    )
    .map((contract) => (
      <CarouselItem
        cloudUtils={cloudUtils}
        contract={contract}
        key={templateId}
        template={templateId}
        admin={admin}
        goBack={goBack}
      />
    ));

  return (
    <Layout title={template.name} onBack={goBack} extra={adminButtons} theme={profile.theme}>
      {admin && !customerPreview ? (
        <TemplateForm
          cloudUtils={cloudUtils}
          setLoading={setLoading}
          form={form2}
          templateId={templateId}
          old={true}
        />
      ) : (
        <div>
          <Info align="center" messages={[template.longDesc]} />

          <Form
            align="left"
            form={form}
            onFinish={async (values) =>
              await submitSurvey(
                { counts, ...values },
                finalPrice,
                cloudUtils,
                { id: templateId, ...template },
                profile
              )
            }
          >
            {!template.purchase && profile.openHours && (
              <Appointment
                day={day}
                setInvalid={setInvalid}
                setDay={setDay}
                duration={template.duration}
                openHours={profile.openHours}
                busy={profile.busy}
                closedDays={profile.closedDays}
              />
            )}
            {!template.price.free &&
            template.bundle &&
            Object.keys(template.bundle).length ? (
              <>
                <Divider displayText="Packages" />
                <RadioInput
                  soquote={Object.entries(template.bundle)}
                  setInitial={setInitial}
                  setBase={setBase}
                  label="bundle"
                  style={{ marginBottom: 0 }}
                />
              </>
            ) : null}
            {template.quote && Object.keys(template.quote).length ? (
              <>
                {initial && (
                  <>
                    <Divider displayText={initial} />
                    <CheckboxInput
                      soquote={Object.entries(template.quote)}
                      style={{ display: "block" }}
                      setTotal={setTotal}
                      label="quote"
                    />
                  </>
                )}
                {Object.entries(template.quote)
                  .filter(([id, { count }]) => count)
                  .map(([id, { feature, price }]) => (
                    <div
                      style={{
                        visibility: initial ? "inherit" : "hidden",
                        height: initial ? "auto" : 0,
                      }}
                    >
                      <Form.Item noStyle>
                        <InputNumber
                          min={0}
                          size="small"
                          onChange={(e) => {
                            counts[id] = parseFloat(price) * e;
                            setCounts({ ...counts });
                          }}
                          style={inputBoxStyle}
                        ></InputNumber>
                      </Form.Item>
                      <span
                        style={{ paddingLeft: 8 }}
                      >{`${feature} - $${price} each`}</span>
                    </div>
                  ))}
              </>
            ) : null}
            <Divider displayText="Information" />
            <TextInput label="name" displayText="Full Name" required={true} />
            <TextInput
              label="email"
              displayText="Email"
              required={true}
              validators={[emailValidator]}
            />
            {template &&
              template.survey &&
              Object.entries(template.survey).map(
                ([sid, { type, question }]) => {
                  return type == "text" ? (
                    <TextInput
                      label={`questionId:${sid}`}
                      displayText={question}
                      required={true}
                    />
                  ) : (
                    <UploadInput
                      label={`questionId:${sid}`}
                      displayText={question}
                      required={true}
                    />
                  );
                }
              )}
          </Form>

          {/* <Space size='small' wrap> */}
          {template.price.free ? (
            <div style={infoBox}>Price: Free!</div>
          ) : (
            <div style={infoBox}>{`Price (${
              template.price.currency
            }): $${finalPrice.toFixed(2)}`}</div>
          )}

          {template.purchase ? (
            <div
              style={infoBox}
            >{`Delivery Estimate (${template.time.unit}): ${template.time.value}`}</div>
          ) : null}
          {/* </Space> */}

          <Submit
            text="Book Now"
            icon={<CalendarOutlined />}
            onClick={form.submit}
            style={{ paddingTop: 16 }}
          />
        </div>
      )}

      <Divider displayText="Reviews" />
      <div className="detailsTemplate">
        <div style={reviewBox}>
          <Carousel widgets={[IndicatorDots]}>
            {displayedReviews.length ? (
              displayedReviews
            ) : (
              <p style={{ "white-space": "pre-wrap" }} align="center">
                There are no reviews for this service yet.
              </p>
            )}
          </Carousel>
        </div>
      </div>
    </Layout>
  );
};
