import { Form } from 'antd';
import { Label } from '../customComponents';

const Input = ({ displayText, label, required = false, validators = [], style, children }) => {

    return (
        <Form.Item
            label={displayText ? <Label>{displayText}</Label> : null}
            name={label}
            rules={[
                {
                    required,
                    message: ``
                },
                ...validators
            ]}
            style={style}
        >
            {children}
        </Form.Item>
    )
};

export default Input