import DotLoader from "react-spinners/DotLoader";
import { useCallback } from "react";
import { PrimaryIcon } from "../customComponents";

export default () => {
  const stopProp = useCallback((e) => {
      e.stopPropagation()
  }, []);

  return (
    <div 
      onClick={stopProp} 
      className="loader"
    >
      <PrimaryIcon icon={<DotLoader/>} size={200}/>
    </div>
  )
}
