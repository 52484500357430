import { Button, Form } from 'antd';
import TextInput from '../inputs/TextInput'
import { useState } from 'react';
import BigTextInput from '../inputs/BigTextInput';
import { pricePositive } from '../utils/validators'
import Currencies from '../elements/Currencies';
import Submit from '../inputs/Submit';

export default ({cloudUtils, setLoading, profile, profileId}) => {
  const [priceValue, setPriceValue] = useState()
  const [currency, setCurrency] = useState('CAD')

  let toTip = cloudUtils.getFunction('toTip')
  const checkoutFn = (values) => {
    setLoading(true)
    toTip({
      desc: values.desc,
      amount: values.amount,
      currency: currency.toLowerCase(),
      profileId,
      stripeId: profile.stripe,
    }).then(({data}) => {
      window.location.href = data.url
      setLoading(false)
    })
  }

    return (
        <Form onFinish={checkoutFn} className="soco-page">
            <BigTextInput label="desc" displayText="Leave a note" required={false}/>
            <TextInput 
            required={true}
            validators={[pricePositive(false, 0, 0, priceValue)]} 
            label="amount" 
            displayText="Tip"
            suffix={<Currencies currency={currency} setCurrency={setCurrency}/>}
            onChange={(e) => {
              setPriceValue(e.target.value)
            }}
            />
            
            <Submit text="Confirm" />

        </Form>
    )
}
